//================================================================
//  Component: Select Vendor Field
//================================================================

//  Purpose: This component allows you to select a vendor from the vendor table or request a new one

//  Properties:
//    - register = {react hooks forms state, connects the field to the form state}
//    - setValue = {react hooks form state, programmatically sets the value for a field}
//    - errors = {react hooks form state, stores the errors for the field}

//  Example:
//  <SelectVendorField
//      register={register}
//      setValue={setValue}
//      errors={errors}
//  ></SelectVendorField> 

//================================================================


//Libraries
import React, { useState } from 'react';

//Contexts

//Components
import LookUpRHF from '../../../../Components/LookUpRHF/LookUpRHF';

//Functions

//Images
import InfoRequired from '../../../../Components/Images/Icon_InfoRequired_Blue.svg';
import GoBack from '../../../../Components/Images/Icon_GoBack_White.svg';

//CSS

export default function SelectVendorField({
  register, 
  setValue, 
  errors,
  clearErrors
}) {

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to determine the current state of the component > 'edit', 'new'
    const [fieldState, setFieldState] = useState('edit')

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    //------------------------------------------------------
    //  Edit State
    //------------------------------------------------------

    if (fieldState === 'edit') {
        return (
            <LookUpRHF
                fieldId={'vendorname'}
                collectionId={'vendors'}
                dataField={'vendorname'}
                placeholder={'Search vendor by name'}
                required={fieldState !== 'new' ? true : false}
                register={register}
                setValue={setValue}
                errors={errors.vendorname}
                dataFieldSecondary={'tradingas'}
                dataFieldTertiary={'vendorid'}
                additionalQueries={['vendorstatus', '==', 'Active']}
                createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <b className='font-medium cursor-pointer text-[#0972D3]' onClick={() => { 
                    setValue('vendorname', '');
                    setFieldState('new');
                } }>here</b>.</span>}
                clearErrors={clearErrors}
            ></LookUpRHF>
        )
    }

    //------------------------------------------------------
    //  New State
    //------------------------------------------------------

    else if (fieldState === 'new') {
        return (
            <div className='flex flex-col justify-center bg-[#E7F0F4] px-4 py-3 rounded-md'>
                
                {/* Header */}
                <div className='flex flex-row gap-2 items-center'>
                    <img src={InfoRequired} alt='info'></img>
                    <label className='font-medium text-[#5E93AC]'>New Vendor Request</label>
                </div>

                {/* Form */}
                <div className='grid grid-cols-2 gap-2'>

                    {/* Column 1 */}
                    <div className='flex flex-col gap-2 px-2 mt-0 mb-2'>

                        {/* Vendor Name */}
                        <div className='Task-Form-Row'>
                            <label className='font-medium'> Vendor Name </label>
                            <input
                                className={errors.newvendorname ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                                style={{ width: '100%' }}
                                {...register('newvendorname', { 
                                    required: fieldState === 'new' && 'This field is required'                    
                                })}
                                placeholder='Enter vendor name'
                                type='text'
                            ></input>

                            {errors.newvendorname && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.newvendorname?.message}</p>}
                        </div>

                        {/* Vendor Phone */}
                        <div className='Task-Form-Row'>
                            <label className='font-medium'> Vendor Phone </label>
                            <input
                                className={errors.newvendorphone ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                                style={{ width: '100%' }}
                                {...register('newvendorphone', { 
                                    required: fieldState === 'new' && 'This field is required',
                                    pattern: {
                                        value: /^\d*[\s()-]*\d*$/,
                                        message: 'Invalid phone number format'
                                    }                
                                })}
                                placeholder='Enter vendor phone'
                                type='text'
                            ></input>

                            {errors.newvendorphone && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.newvendorphone?.message}</p>}
                        </div>

                    </div>

                    {/* Column 2 */}
                    <div className='flex flex-col gap-2 px-2 mt-0 mb-2'>

                        {/* Vendor Trading As Name */}
                        <div className='Task-Form-Row'>
                            <label className='font-medium'> Vendor Trading As Name </label>
                            <input
                                className={errors.newvendortradingname ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                                style={{ width: '100%' }}
                                {...register('newvendortradingname', { 
                                    required: fieldState === 'new' && 'This field is required'                    
                                })}
                                placeholder='Enter vendor trading name'
                                type='text'
                            ></input>

                            {errors.newvendortradingname && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.newvendortradingname?.message}</p>}
                        </div>

                        {/* Vendor Email */}
                        <div className='Task-Form-Row'>
                            <label className='font-medium'> Vendor Email </label>
                            <input
                                className={errors.newvendoremail ? ('Input-Field-Text-Error'): ('Input-Field-Text')}
                                style={{ width: '100%' }}
                                {...register('newvendoremail', { 
                                    required: fieldState === 'new' && 'This field is required'                    
                                })}
                                placeholder='Enter vendor email'
                                type='text'
                            ></input>

                            {errors.newvendoremail && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.newvendoremail?.message}</p>}
                        </div>

                    </div>
                </div>
                
                {/* Return Button */}
                <div className='flex justify-end my-2 mx-4'>
                    <button className='Primary-Button flex flex-row gap-2' onClick={() => {

                        // Reset values
                        setValue('newvendorname', '');
                        setValue('newvendorphone', '');
                        setValue('newvendortradingname', '');
                        setValue('newvendoremail', '');

                        // Return to 'Select a Vendor'
                        setFieldState('edit');

                    }}>
                        <img src={GoBack} alt='return'></img>
                        Choose Existing Vendor
                    </button>
                </div>

            </div>
        )
    }

}
