//================================================================
//  Component: Proceed to Next Stage
//================================================================

//  Purpose: This is the button on the Diligence and Governance Tab for proceeding to the next stage (i.e. Publishing to Inventory)

//  Properties:
//    - task = {A useState, contains the task details}

//  Example:
//    <ProceedToNextStage
//      task={task}
//    ></ProceedToNextStage>    

//================================================================


//Libraries
import React, { useState, useEffect } from 'react';

//Contexts

//Components
import PublishInventoryConfirmationModal from '../Modals/PublishInventoryConfirmationModal';

//Functions
import QueryListener from '../../../../Library/QueryListener';
import WriteDocument from '../../../../Library/WriteDocument';

//Images

//CSS


export default function ProceedToNextStage({
    task
}) {

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

    // Approval Tasks
    const [approvalTasks, setApprovalTasks] = useState([]);

    // Toggle Proceed to Next Stage Modal visibility
    const [proceedModal, setProceedModal] = useState(false);

    //------------------------------------------------------
    //  useEffects
    //------------------------------------------------------

    // Get all the related tasks
    useEffect(() => {

        if (task === undefined) return;
        if (task?.stage === 'Publishing') return;
        if (task?.stage === 'Ready to Publish') return;

        function onLoadChange(documents) {

            setApprovalTasks(documents);

            // If there are no pending tasks > change stage to 'Ready to Publish'
            if (task?.stage === 'Publishing') return;
            if (documents.filter((item) => item.tasktype !== 'Business Request')?.length === 0) return;

            // If there are pending tasks > change stage to ''
            WriteDocument('tasks', task.taskid, { 'stage': '' }, true)
            .catch((error) => {

                console.log(error);

            });

        }

        function onError(error) {
            console.log(error);

        }

        const unsubscribe = QueryListener('tasks', [
            ['requestid', '==', task?.requestid],
            ['status', 'not-in', ['Completed', 'Cancelled', 'Rejected']]

        ], onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, []);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (

        <>

            {/* ------------------------------------------------------------- */}
            {/*  Proceed to Next Stage Button                                 */}
            {/* ------------------------------------------------------------- */}

            <div className='flex flex-col gap-1 mt-4 mb-0'>

                <button
                    className='Primary-Button w-fit'
                    onClick={() => {
                        
                        WriteDocument('tasks', task.taskid, { 'stage': 'Ready to Publish' }, true)
                        .then(() => {

                            setProceedModal(true);

                        })
                        .catch((error) => {
                    
                            console.log(error);
                    
                        });
           
                    }}
                    disabled={
                        approvalTasks.filter((item) => item.tasktype !== 'Business Request')?.length > 0 || task?.stage === 'Publishing' ? true : false 
                    }
                >
                    Proceed to Next Stage
                </button>
                <label className='text-[14px] text-slate-600 italic'>All raised tasks must be completed before proceeding to the next stage.</label>

            </div>


            {/* ------------------------------------------------------------- */}
            {/*  Publish to Inventory Modal                                   */}
            {/* ------------------------------------------------------------- */}

            <PublishInventoryConfirmationModal
                modalVisible={proceedModal}
                setModalVisible={setProceedModal}
                task={task}
            ></PublishInventoryConfirmationModal>

        </>

    );

}
