//================================================================
//  Component: Initial Asssessment Tab
//================================================================

//  Purpose: This contains the initial assessment of the business request

//  Properties:
//    - task = {An object, contains the business request task details}
//    - setPageStatus = {A useState, used to determine the status of the page}

//  Example:
//    <InitialAssessmentTab
//      task={task}
//      setPageStatus={setPageStatus}
//    ></InitialAssessmentTab>    

// ==============================================================

// Libraries
import React, { useState, useReducer, useEffect } from 'react'

// Contexts

// Components
import CSMQuestionnaire from './CSMQuestionnaire';
import CommentsThread from './CommentsThread';

//Functions
import QueryListener from '../../../../Library/QueryListener';
import ConvertDate from '../../../../Library/ConvertDate';

// Images
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';

// CSS
import '../../Task.css'

export default function InitialAssessmentTab({
    task,
    setPageStatus
}) {

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------
        
    // Used to store the initial assessment tasks
    const [initialAssessmentTasks, setInitialAssessmentTasks] = useState([]);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded
    const [taskSection, setTaskSection] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'initialAssessmentCollapsed': true,
            'csmQuestionnaireCollapsed': true,
            'commentsCollapsed': true
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    useEffect(()=>{
    
      if (task === undefined) return;
    
      function onLoadChange(document){
        setInitialAssessmentTasks(document);
      }
    
      function onError(error){
        console.log(error);
        setPageStatus('error-fatal');
      }
    
      const unsubscribe = QueryListener('tasks', [
        ['requestid', '==', task?.requestid],
        ['assignmentgroup', '==', 'CSM'],
        ['tasktype', 'in',  ['Line Manager Approval', 'Finance Manager Approval', 'Instant Vendor Risk Report']]
      ], onLoadChange, onLoadChange, onError);
    
      return () =>{
        unsubscribe();
      };
    
    // eslint-disable-next-line
    }, [task]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 p-0'>

            {/* ===================================================== */}
            {/*  Initial Assessment                                   */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>
                
                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'initialAssessmentCollapsed': !taskSection.initialAssessmentCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.initialAssessmentCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Initial Assessment</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.initialAssessmentCollapsed}></hr>

                {/* ------------- INITIAL ASSESSMENT ------------- */}

                <div className='p-[3%]' hidden={taskSection?.initialAssessmentCollapsed}>

                    <div className='border border-[#D8D8D8] rounded px-[1%]'>
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr className='text-[#424242]'>
                                    <th className='py-[15px] px-[5px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>Type</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Assigned To</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Modified By</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                                    <th className='py-[15px] px-[10px] font-medium'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    initialAssessmentTasks?.length === 0 || initialAssessmentTasks === undefined ?
                                    (
                                        <tr>
                                            <td className='border-t border-t-[#D8D8D8]' colSpan={7}>
                                                <div className='text-center p-[2%]'>
                                                    No tasks found.
                                                </div>
                                            </td>
                                        </tr>
                                    )
                                    :
                                    (
                                        initialAssessmentTasks?.map((assessmentTask, index) => (
                                            <tr key={index} className='hover:bg-[#F0F7F7]'>

                                                <td className='py-[10px] px-[5px] border-t border-t-[#D8D8D8]'></td>

                                                {/* Role */}
                                                <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8] capitalize'>
                                                    {assessmentTask?.tasktype}
                                                </td>

                                                {/* Assigned To */}
                                                <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                    {
                                                        assessmentTask?.assignedto?.email === 'Unassigned' ?
                                                        <label>Unassigned</label>:
                                                        <div className='flex flex-col'>
                                                            <label className='capitalize'>{assessmentTask?.assignedto?.givenname} {assessmentTask?.assignedto?.surname} </label>
                                                            <label className='text-[14px] text-[#616161]'>{assessmentTask?.assignedto?.email}</label>
                                                        </div>
                                                    }
                                                </td>

                                                {/* Modified By */}
                                                <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8] flex flex-col'>
                                                    <label className='capitalize'>{assessmentTask?.lastmodifiedby?.givenname} {assessmentTask?.lastmodifiedby?.surname} </label>
                                                    <label className='text-[14px] text-[#616161]'>{assessmentTask?.lastmodifiedby?.email}</label>
                                                </td>

                                                {/* Modified Date */}
                                                <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                    {ConvertDate(assessmentTask?.lastmodifieddate)?.split(',')[0]}
                                                </td>

                                                {/* Status */}
                                                <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                    <div>
                                                    {
                                                        [
                                                        {'name': 'Not Started', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Not Started'},
                                                        {'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress'}, 
                                                        {'name': 'Completed', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed'},
                                                        {'name': 'Cancelled', 'background': '#EBEBEB', 'color': '#616161', 'bordercolor': '#616161', 'status': 'Cancelled'},
                                                        {'name': 'Rejected', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Rejected'},

                                                        ].filter((style) => style?.status === assessmentTask?.status).map((object, index) => (
                                                        <label key={index} className='Status-Label' style={{backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}`}} >
                                                            {object.name}
                                                        </label>
                                                        ))
                                                    }
                                                    </div>
                                                </td>
                                                
                                                {/* Cancel */}
                                                <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'></td>

                                                
                                            </tr>
                                        ))
                                    )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  CSM Questionnaire                                    */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>
                
                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'csmQuestionnaireCollapsed': !taskSection.csmQuestionnaireCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.csmQuestionnaireCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'> CSM Questionnaire </h4>
                </div>
                <hr className='m-0' hidden={taskSection?.csmQuestionnaireCollapsed}></hr>

                {/* ------------- CSM Questionnaire ------------- */}

                <div className='p-[3%]' hidden={taskSection?.csmQuestionnaireCollapsed}>

                    <CSMQuestionnaire
                        collectionid='tasks'
                        documentid={task?.taskid}
                        task={task}
                    ></CSMQuestionnaire>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Comments                                             */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>
                
                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'commentsCollapsed': !taskSection.commentsCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.commentsCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'>Comments</h4>
                </div>
                <hr className='m-0' hidden={taskSection?.commentsCollapsed}></hr>

                {/* ------------- COMMENTS ------------- */}

                <div className='p-[3%]' hidden={taskSection?.commentsCollapsed}>
                    <CommentsThread
                        task={task}
                    ></CommentsThread>

                </div>

            </div>

        </div>
    );
    
}
