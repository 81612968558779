//================================================================
//  Component: Cancel Approval Modal
//================================================================

//  Purpose: This modal will allow users to an existing approval for a csm task

//  Properties:
//    - cancelModalApprover = {useState, used to store the current approval and determine if the modal is visible}
//    - setCancelModalApprover = {useState, used to toggle the visibility of the cancel approval modal}

//  Example:
//    <CancelApprovalModal
//      cancelModalApprover={cancelModalApprover}
//      setCancelModalApprover={setCancelModalApprover}
//    ></CancelApprovalModal> 

//================================================================

//Libraries
import React, { useState, useContext } from 'react';

//Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

//Components

//Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Images
import Error from '../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import Warning from '../../../../Components/Images/Icon_Warning_Red.svg';

export default function CancelApprovalModal({
  cancelModalApprover, 
  setCancelModalApprover
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

    const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

    // Used to set modal status > 'onload', 'error'
    const [modalStatus, setModalStatus] = useState('onload');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

    // Used to cancel an existing approval 
    function cancelApproval() {

      const updatedApprovalTask = {
        'status': 'Cancelled',
        'lastmodifieddate': new Date(),  
        'lastmodifiedby': {
          'email': getUser?.emailaddress,
          'givenname': getUser?.givenname,
          'surname': getUser?.surname    
        },
        'activityfeed': cancelModalApprover?.activityfeed
      }

      // Update the activity feed
      updatedApprovalTask.activityfeed.push({
        'activitydate': new Date(),
        'actionedby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
        },
        'action': 'cancelled',
        'comments': '',
      })

      return WriteDocument('tasks', cancelModalApprover?.taskid, updatedApprovalTask, true)
      .then(() =>{

        // Close Modal
        setCancelModalApprover(undefined);

      })
      .catch((error) =>{

        console.log(error);
        setModalStatus('error');
      
      })

    }
    
  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    if (cancelModalApprover === undefined) return null;

    // ---------------------------------------------------
    //  Onload
    // ---------------------------------------------------

    if (modalStatus === 'onload') {
      return (
        <div className='Modal-Background'>
          
          {/* Modal Container */}
          <dialog className='Warning-Modal-Container'>

                {/* Header */}
                <div className='flex flex-row gap-3 items-center'>
                    <img src={Warning} alt='Warning'></img>
                    <h4>You are about to cancel this request</h4>
                </div>

                {/* Body */}
                <p className='max-w-[450px] mt-2 mb-3'> 
                    Are you sure you want to cancel this request? This change cannot be undone. 
                </p>

                {/* Buttons */}
                <div className='flex flex-row gap-2'>
                    <button className='Warning-Button' onClick={() => cancelApproval()}>Cancel Request</button>
                    <button 
                        className='Secondary-Button' 
                        style={{color: '#424242', border: '1px solid #424242'}} 
                        onClick={() => setCancelModalApprover(undefined)}
                    >
                        Cancel
                    </button>
                </div>

          </dialog>
        </div>
      )
    }

    // ---------------------------------------------------
    //  Error
    // ---------------------------------------------------
    
    else if (modalStatus === 'error') {
      return (
        <div className='Modal-Background'>
          
          {/* Modal Container */}
          <dialog className='Warning-Modal-Container justify-center items-center'>
    
            <img className='my-1 w-[50px]' src={Error} alt='Error'></img> 

            <h4 className='mb-0'>Oops! Something went wrong.</h4>

            <p className='text-center leading-[1.7]'>
              An error occurred while we processed your request.
            </p>

            <button 
                className='Warning-Button' 
                onClick={() => {
                    setModalStatus('onload');
                    setCancelModalApprover(undefined);
                }}
            >
                Close
            </button>

          </dialog>

        </div>
      )
    }
    
  //------------------------------------------------------
}