//================================================================
//  Component: RequestPane
//================================================================

//  Purpose: This side pane allows a user to view a digital business improvement request

//  Properties:
//    - paneOpen = { useState, holds a boolean value that opens and closes the sidePane}
//    - setPaneOpen = { useState, updates the boolean value that opens and closes the sidePane}
//    - selectedRequest = { useState, stores the currently selected request}
//    - setSelectedRequest = { useState, used to set the selected request }

//  Example:
//  
//    <RequestPane
//      paneOpen={openPane}
//      setPaneOpen={setOpenPane}
//      selectedRequest={selectedRequest}
//      setSelectedRequest={setSelectedRequest}
//    ></RequestPane>

//================================================================

// Libraries
import React from 'react';

// Contexts

// Functions

// Images

export default function RequestPane({
    paneOpen,
    setPaneOpen,
    selectedRequest,
    setSelectedRequest
}) {

    // --------------------------------------------------
    //  HTML
    // --------------------------------------------------

        // ==================================================
        //  Hide Side Pane
        // ==================================================

        if (!paneOpen) return null;

        // ==================================================
        //  Show Side Pane  
        // ==================================================

        return (
            <div className='Pane-Background'>  
                <dialog className='Pane-Container'>
                    <div className='flex flex-col gap-3 justify-between'>

                        {/* ===================================== */}
                        {/*  Business Request Form                */}
                        {/* ===================================== */}

                        <div className='Request-Form-Container'>

                            {/* ===================================== */}
                            {/*  Header                               */}
                            {/* ===================================== */}
                            
                            <h4 className='px-[5%] py-[2.5%] mb-0 text-[20px]'> Business Request | {selectedRequest?.requestid.toUpperCase()} </h4>
                            <hr className='m-0'></hr>

                            {/* ===================================== */}
                            {/*  Form                                 */}
                            {/* ===================================== */}

                            <div className='px-[5%] py-[4%]'>

                                {/* =========================================================== */}
                                {/*  1. What is the name of this application?                   */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 1. What is the name of this application? </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.applicationname}
                                        disabled
                                    ></input>
                                </div>

                                {/* ================================================================ */}
                                {/*  2. Please provide a short description of your request. *        */}
                                {/* ================================================================ */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 2. Please provide a short description of your request. * </label>
                                    <textarea
                                        className='Input-Field-TextArea'
                                        type='text'
                                        value={selectedRequest?.taskdescription}
                                        disabled
                                    ></textarea>
                                </div>
                
                                {/* ============================================================================ */}
                                {/*  3. Please list business processes that this application is used for. *      */}
                                {/* ============================================================================ */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 3. Please list business processes that this application is used for. * </label>
                                    <textarea
                                        className='Input-Field-TextArea'
                                        type='text'
                                        value={selectedRequest?.shortdescription}
                                        disabled
                                    ></textarea>
                                </div>
                
                                {/* =========================================================== */}
                                {/*  4. Who is the business owner of this application?          */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 4. Who is the business owner of this application? </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.businessowner}
                                        disabled
                                    ></input>
                                </div>

                                {/* ========================================================================= */}
                                {/*  5. Who is the project lead / business lead supporting this application?  */}
                                {/* ========================================================================= */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 5. Who is the project lead / business lead supporting this application? </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.businesslead}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  6. Who is your line manager?                               */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 6. Who is your line manager? </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.linemanager}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  7. Who is the finance manager for this request?            */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 7. Who is the finance manager for this request? </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.financemanager}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  8. Project Code                                            */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 8. Project Code </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{ width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.projectcode}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  9. Task Number                                             */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 9. Task Number </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{ width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.tasknumber}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  10. Expenditure Type                                       */}
                                {/* =========================================================== */}                                
                                
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 10. Expenditure Type </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{ width: '200px'}}
                                        type='text'
                                        value={selectedRequest?.expendituretype}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  11. Who is budgeting for this?                             */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 11. Who is budgeting for this? </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.budgeting}</option>
                                    </select>
                                </div>

                                {/* ==================================================================================== */}
                                {/*  12. Who will be the primary beneficiary of this business improvement solution?      */}
                                {/* ==================================================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 12. Who will be the primary beneficiary of this business improvement solution? </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.primarybeneficiary}</option>
                                    </select>
                                </div>

                                {/* ======================================================================================= */}
                                {/*  13. Who will be the secondary beneficiary(s) for this business improvement solution?   */}
                                {/* ======================================================================================= */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 13. Who will be the secondary beneficiary(s) for this business improvement solution? </label>
                                    <select className='Input-Field-MultiSelect' value={selectedRequest?.secondarybeneficiary} multiple disabled>
                                        <option hidden value=''>-</option>
                                        <option value='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                                        <option value='EHS'> EHS </option>
                                        <option value='Finance'> Finance </option>
                                        <option value='Group Investor Relations'> Group Investor Relations </option>
                                        <option value='IT'> IT </option>
                                        <option value='Legal'> Legal </option>
                                        <option value='People & Culture'> People & Culture </option>
                                        <option value='Risk and Insurance'> Risk and Insurance </option>
                                        <option value='Supply Chain'> Supply Chain </option>
                                        <option value='Sustainability'> Sustainability </option>
                                        <option value='Tax'> Tax </option>
                                        <option value='Treasury'> Treasury </option>
                                    </select>
                                </div>

                                {/* ===================================================================== */}
                                {/*  14. Commercial Entity to sign application agreement (if known)       */}
                                {/* ===================================================================== */}                                
                                
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 14. Commercial Entity to sign application agreement (if known) </label>
                                    <input
                                        className='Input-Field-Text'
                                        type='text'
                                        value={selectedRequest?.commercialentity}
                                        disabled
                                    ></input>
                                </div>

                                {/* =========================================================== */}
                                {/*  15. Approximate number of users                            */}
                                {/* =========================================================== */}                                
                                
                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 15. Approximate number of users </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{ width: '200px'}}
                                        type='number'
                                        value={selectedRequest?.totalusers}
                                        disabled
                                    ></input>
                                </div>

                                {/* ======================================= */}
                                {/*  16. What type of request is this?      */}
                                {/* ======================================= */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 16. What type of request is this? </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.requesttype}</option>
                                    </select>
                                </div>

                                {/* =================================================================== */}
                                {/*  17. Will this application improve one or more of the following?    */}
                                {/* =================================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 17. Will this application improve one or more of the following?  </label>
                                    <select className='Input-Field-MultiSelect' value={selectedRequest?.improvements} multiple disabled>
                                        <option hidden value=''>-</option>
                                        <option value='Business efficiency'> Business efficiency </option>
                                        <option value='Customer / Client Satisfaction'> Customer / Client Satisfaction </option>
                                        <option value='Enhancement of existing business critical systems'> Enhancement of existing business critical systems </option>
                                        <option value='ESG requirements or credentials'> ESG requirements or credentials </option>
                                        <option value='Investment in our people'> Investment in our people </option>
                                        <option value='Overcome risks associated with shadow IT'> Overcome risks associated with shadow IT </option>
                                        <option value='Regulatory compliance'> Regulatory compliance </option>
                                        <option value='Risk mitigation'> Risk mitigation </option>
                                    </select>
                                </div>

                                {/* ========================================================================================= */}
                                {/*  18. Provide a summary of how this initiative will improve the items chosen above.        */}
                                {/* ========================================================================================= */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 18. Provide a summary of how this initiative will improve the items chosen above. </label>
                                    <textarea
                                        className='Input-Field-TextArea'
                                        type='text'
                                        value={selectedRequest?.summary}
                                        disabled
                                    ></textarea>
                                </div>

                                {/* =========================================================== */}
                                {/*  19. Does this initiative drive revenue growth?             */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 19. Does this initiative drive revenue growth? </label>
                                    <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                        <label className='Radio-Buttons-Container'>
                                            Yes
                                            <input type='radio' name='revenuegrowth' value='yes' defaultChecked={selectedRequest?.revenuegrowth.toLowerCase() === 'yes'} disabled></input>
                                            <span className='Radio-Checkmark'></span>
                                        </label>

                                        <label className='Radio-Buttons-Container'>
                                            No
                                            <input type='radio' name='revenuegrowth' value='no' defaultChecked={selectedRequest?.revenuegrowth.toLowerCase() === 'no'} disabled></input>
                                            <span className='Radio-Checkmark'></span>
                                        </label>
                                    </div>
                                </div>

                                {/* =========================================================== */}
                                {/*  19a. If yes, when would revenue growth commence?*          */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row' hidden={selectedRequest?.revenuegrowth.toLowerCase() === 'no'}>
                                    <label className='font-medium'> 19. Does this initiative drive revenue growth? </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.arevenuegrowthcommence}</option>
                                    </select>
                                </div>

                                {/* =========================================================== */}
                                {/*  20. Does this product accelerate funds growth?             */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 20. Does this product accelerate funds growth? </label>
                                    <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                                        <label className='Radio-Buttons-Container'>
                                            Yes
                                            <input type='radio' name='fundsgrowth' value='yes' defaultChecked={selectedRequest?.fundsgrowth.toLowerCase() === 'yes'} disabled></input>
                                            <span className='Radio-Checkmark'></span>
                                        </label>

                                        <label className='Radio-Buttons-Container'>
                                            No
                                            <input type='radio' name='fundsgrowth' value='no' defaultChecked={selectedRequest?.fundsgrowth.toLowerCase() === 'no'} disabled></input>
                                            <span className='Radio-Checkmark'></span>
                                        </label>
                                    </div>
                                </div>

                                {/* =========================================================== */}
                                {/*  20a. If yes, when would funds growth commence? *           */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row' hidden={selectedRequest?.fundsgrowth.toLowerCase() === 'no'}>
                                    <label className='font-medium'> 20a. If yes, when would funds growth commence? </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.afundsgrowthcommence}</option>
                                    </select>
                                </div>

                                {/* ========================================================== */}
                                {/*  21. What LoA Type is this initiative / application?       */}
                                {/* ========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 21. What LoA Type is this initiative / application?  </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.loatype}</option>
                                    </select>
                                </div>

                                {/* ==================================================================== */}
                                {/*  22. What is the anticipated complete cost of this initiative?       */}
                                {/* ==================================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> 22. What is the anticipated complete cost of this initiative? </label>
                                    <select className='Input-Field-Select' disabled>
                                        <option>{selectedRequest?.anticipatedcost}</option>
                                    </select>
                                </div>

                                {/* =================================================== */}
                                {/*  Comments                                           */}
                                {/* =================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> Comments </label>
                                    {
                                        selectedRequest?.comments.length === 0 ?
                                        <input
                                            className='Input-Field-Text'
                                            type='text'
                                            value='-'
                                            disabled
                                        ></input>
                                        :
                                        <textarea
                                            className='Input-Field-TextArea'
                                            type='text'
                                            value={selectedRequest?.comments}
                                            disabled
                                        ></textarea>
                                    }
                                </div>

                                {/* =========================================================== */}
                                {/*  Created By                                                 */}
                                {/* =========================================================== */}

                                <div className='FormComponent-Row'>
                                    <label className='font-medium'> Created By </label>
                                    <input
                                        className='Input-Field-Text'
                                        style={{width: '300px'}}
                                        type='text'
                                        value={selectedRequest?.createdby?.email}
                                        disabled
                                    ></input>
                                </div>

                            </div>

                        </div>

                        {/* ===================================== */}
                        {/*  Close Button                         */}
                        {/* ===================================== */}

                        <div className='flex flex-row gap-2'>
                            <button className='Primary-Button' onClick={() => {

                                // Reset Selected Request
                                setSelectedRequest();

                                // Close Side Pane
                                setPaneOpen(false);
                                
                            }}>
                                Close
                            </button>
                        </div>

                    </div>
                </dialog>
            </div>
        );
}
