//================================================================
//  Component: File Upload Live Fields
//  Created by Nowshin Hassan
//================================================================

//  Purpose: This component allows you to upload multiple files

//  Properties:
//    - label = {HTML, Label Tag}
//    - defaultValue = {A string, onload value for the input}
//    - collectionid = {String, Firestore collection id}
//    - documentid = {String, Firestore document id}
//    - fieldpath = {String, Firestore field path}
//    - disabled = {boolean, determines when the file input should be disabled}
//    - allowedFileTypes = <OPTIONAL> {array, this is an array of all of the allowed file types}
//    - allowedFileSize = <OPTIONAL> {number, this the total allowed file size in MB}

//  Example:
//  <FileUploadLiveField
//      label={<label className='font-medium'> If yes, please upload your endorsement. </label>}
//      defaultValue={task?.csmquestionnaire?.idcendorsement}
//      collectionid={collectionid}
//      documentid={documentid}
//      fieldpath={'csmquestionnaire.idcendorsement'}
//      disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
//  ></FileUploadLiveField>

//================================================================


// Libraries
import React, { useState, useReducer, useEffect, useContext } from 'react';

// Contexts
import { GetUser } from '../../Library/GlobalContexts';

// Components
import Tooltip from '../Tooltip/Tooltip';

// Functions
import UploadFile from '../../Library/UploadFile';
import WriteDocument from '../../Library/WriteDocument';
import AddRemoveDocumentArray from '../../Library/AddRemoveDocumentArray';
import DeleteFile from '../../Library/DeleteFile';

// Images
import LoadingIcon from '../Images/Icon_LoadingFile_Grey.gif';
import TryAgain from '../Images/Icon_TryAgain_DarkRed.svg';
import Error from '../Images/Icon_Error_DarkRed.svg';
import FileIcon from '../Images/Icon_File_Black.svg';
import DeleteDisabled from '../Images/Icon_DeleteDisabled_Grey.svg';

// CSS
import './FileUploadLiveField.css';

export default function FileUploadLiveField({
    label,
    allowedFileTypes,
    allowedFileSize,
    defaultValue,
    fieldpath,
    collectionid,
    documentid,
    disabled,
}) {

    //------------------------------------------------------
    //  useContext
    //------------------------------------------------------

        const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useStates
    //------------------------------------------------------

        // Handles the state of the upload > 'onchange', 'pending', 'error-fatal'
        const [uploadStatus, setUploadStatus] = useState('onchange');

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

        // Used to store the file(s) and errors
        const [fileData, setFileData] = useReducer(
        (state, newState) => ({...state, ...newState}),
            {
                'documents': [],
                'invalidFileTypeErrorMessage': '',
                'invalidFileSizeErrorMessage': '',
                'genericErrorMessage': ''
            }
        );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

        // File Selector > Event Handler
        function handleChange(file){

            //------------------------------------------------------
            //  Validate incoming file 
            //------------------------------------------------------
            
            let preventSubmit = false;

            if (file === false)  {

                preventSubmit = true;
                fileData.genericErrorMessage ='Please select a file';

            }

            // Validate File Types
            let fileType;
            fileType = file?.name.split('.');
            fileType = fileType[fileType?.length - 1].toLowerCase();

            if (allowedFileTypes !== undefined || allowedFileTypes?.length > 0) {

                if (allowedFileTypes.includes(fileType) === false) {

                    preventSubmit = true;
                    fileData.invalidFileTypeErrorMessage =`Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}`;
        
                }

            }

            //------------------------------------------------------
            //  Client side validation --> Otherwise the upload MUST complete before the storage rules reject the request
            //------------------------------------------------------

            if (allowedFileSize !== undefined) {

                if (file.size/1000000 > allowedFileSize) {

                    preventSubmit = true;
                    fileData.invalidFileSizeErrorMessage = `The file must be smaller than ${allowedFileSize} MB.`; // Convert Bytes to MB
                
                }

            }

            setFileData(fileData);

            // Check if ALL validations have passed
            if (preventSubmit === true) return;

            //------------------------------------------------------
            //  Passed all checks > Proceed with upload
            //------------------------------------------------------

            setUploadStatus('pending');

            const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`

            //Upload the file to GCS
            UploadFile(`${collectionid}/${documentid}/${fileId}`, file)
            .then((url) => {

                const fileObject = {
                    'fileUrl': url,
                    'fileId': fileId,
                    'fileName': file.name,
                    'fileType': fileType
                };

                // Save the file URL and file to fileData
                fileData['documents'].push(fileObject);
                fileData['invalidFileTypeErrorMessage'] = '';
                fileData['invalidFileSizeErrorMessage'] = '';
                fileData['genericErrorMessage'] = '';
                setFileData(fileData);

                //--------- UPDATE FIRESTORE -----------//

                const docWritePromises = [];

                // Update array of documents
                docWritePromises.push(
                    AddRemoveDocumentArray(collectionid, documentid, fieldpath, fileObject, 'add'),
                );

                // Update last modified date
                const document = {
                    'lastmodifieddate': new Date(),
                    'lastmodifiedby': {
                        'email': getUser?.emailaddress,
                        'givenname': getUser?.givenname,
                        'surname': getUser?.surname
                    },
                };

                docWritePromises.push(
                    WriteDocument(collectionid, documentid, document, true),
                );

                // Resolve Promises
                Promise.all(docWritePromises)
                .then(() => {

                    setUploadStatus('onchange');

                });

            })
            .catch((error) =>{

                console.log(error)
                setFileData({ genericErrorMessage: error.message });
                setUploadStatus('error-fatal');

            });

        }

        // Try Again Button Handler
        function handleUploadStatus(status){

            setFileData({ 
                'invalidFileTypeErrorMessage': '',
                'invalidFileSizeErrorMessage': '',
                'genericErrorMessage': ''            
            });

            setUploadStatus(status);

        }

        // Remove file handler
        function removeFileHandler(file) {

            DeleteFile(`${collectionid}/${documentid}/${file?.fileId}`)
            .then(() => {

                // Remove the file from fileData
                fileData['documents'] = fileData['documents'].filter(item => item!== file);
                fileData['invalidFileTypeErrorMessage'] = '';
                fileData['invalidFileSizeErrorMessage'] = '';
                fileData['genericErrorMessage'] = '';

                setFileData(fileData);

                //--------- UPDATE FIRESTORE -----------//

                const docWritePromises = [];

                // Update array of documents
                docWritePromises.push(
                    AddRemoveDocumentArray(collectionid, documentid, fieldpath, file, 'remove'),
                )

                // Update last modified date
                const document = {
                    'lastmodifieddate': new Date(),
                    'lastmodifiedby': {
                        'email': getUser?.emailaddress,
                        'givenname': getUser?.givenname,
                        'surname': getUser?.surname
                    },
                };

                docWritePromises.push(
                    WriteDocument(collectionid, documentid, document, true),
                );

                // Resolve Promises
                Promise.all(docWritePromises)

            })
            .catch((error) =>{
                console.log(error)
                setFileData({ genericErrorMessage: error.message });
                setUploadStatus('error-fatal');

            });

        }


    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

        // Onload > Populate useReducer with default value
        useEffect(() => {

            if (defaultValue === undefined) return;

            setFileData({ 'documents': defaultValue });

        }, [defaultValue]);

    //------------------------------------------------------
    //  Return HTML
    //------------------------------------------------------

        //------------------------------------------------------
        //  Upload Status > 'onchange'
        //------------------------------------------------------

        if (uploadStatus === 'onchange') {

            return (
                <div className='flex flex-col gap-3 my-[20px]'>

                    {/* --------------- LABEL ------------------- */}

                    {label}

                    {/* --------------- FILE INPUT ------------------- */}

                    <div className='Attach-File-Onload-Container'>

                        {/* File Input Field */}
                        <input
                            className={
                                (fileData?.invalidFileTypeErrorMessage.length === 0 && fileData?.invalidFileSizeErrorMessage.length === 0 && fileData?.genericErrorMessage.length === 0) ? 
                                ('Input-Field-Text') 
                                : 
                                ('Input-Field-Text-Error')
                            }
                            style={{padding: '0px'}}
                            type='file'
                            onChange={(e) => handleChange(e.target.files[0])}
                            disabled={disabled}
                        ></input>

                    </div>

                    {/* --------------- ERROR MESSAGE ------------------- */}

                    {/* Invalid File Type Error Message */}
                    <label 
                        className='w-full flex flex-row gap-2 items-center border-1 border-solid border-[darkred] text-[darkred] text-sm bg-[rgb(255,_211,_211)] rounded-[5px] px-3 py-2'
                        hidden={fileData?.invalidFileTypeErrorMessage?.length === 0}
                    >
                        <img src={Error} alt='Error'></img>
                        {fileData?.invalidFileTypeErrorMessage}
                    </label>

                    {/* Invalid File Size Error Message */}
                    <label 
                        className='w-full flex flex-row gap-2 items-center border-1 border-solid border-[darkred] text-[darkred] text-sm bg-[rgb(255,_211,_211)] rounded-[5px] px-3 py-2'
                        hidden={fileData?.invalidFileSizeErrorMessage?.length === 0}
                    >
                        <img src={Error} alt='Error'></img>
                        {fileData?.invalidFileSizeErrorMessage}
                    </label>

                    {/* Generic Error Message */}
                    <label 
                        className='w-full flex flex-row gap-2 items-center border-1 border-solid border-[darkred] text-[darkred] text-sm bg-[rgb(255,_211,_211)] rounded-[5px] px-3 py-2'
                        hidden={fileData?.genericErrorMessage?.length === 0}
                    >
                        <img src={Error} alt='Error'></img>
                        {fileData?.genericErrorMessage}
                    </label>

                    {/* --------------- FILE TABLE ------------------- */}

                    {/* File Uploads Table */}
                    {
                        fileData?.documents.length > 0 &&
                        <div className='w-full flex flex-col text-[#0972D3] rounded-md underline border border-[#D8D8D8]'>
                            {
                                fileData?.documents.map((doc, index) => (
                                <div key={index} className='flex flex-row justify-between items-center border-b border-b-[#D8D8D8] last:border-0 px-3 py-1'>

                                    {/* File Name */}
                                    <div className='flex flex-row gap-2 items-center'>
                                        <img src={FileIcon} alt='file'></img>
                                        <a href={doc?.fileUrl} target='_blank' rel='noopener noreferrer'>{doc?.fileName}</a>
                                    </div>
                                    
                                    {/* Delete Button */}
                                    <div>
                                        {
                                            !disabled ?
                                            <img className='cursor-pointer my-1' src={DeleteDisabled} alt='delete'></img>
                                            :
                                            <Tooltip
                                                message={'Delete File'}
                                                children={
                                                    <div className='Cancel-Icon cursor-pointer mt-1' onClick={() => removeFileHandler(doc)}></div>
                                                }
                                            ></Tooltip>
                                        }
                                    </div>

                                </div>
                            ))
                            }           
                        </div>
                    }

                </div>
            )

        }

        //------------------------------------------------------
        //  Upload Status > 'pending'
        //------------------------------------------------------

        else if (uploadStatus === 'pending') {

            return (
                <div className='flex flex-row justify-start items-center text-left gap-2 my-[20px]'>
                    <img src={LoadingIcon} alt='loading-icon' width='30px' height='30px'></img>
                    Uploading...
                </div>
            )

        }

        //------------------------------------------------------
        //  Upload Status > 'error-fatal'
        //------------------------------------------------------

        else if (uploadStatus === 'error-fatal') {

            return (
                <div className='flex flex-col gap-[10px 0px] my-[20px]'>
                    <label className='w-full flex flex-row border-1 border-solid border-[darkred] text-[darkred] text-sm bg-[rgb(255,_211,_211)] rounded-[5px] px-3 py-2 my-2'>
                    Failed to upload file. 
                    <button className='flex flex-row gap-1 items-center mx-1 font-semibold underline' onClick={() => handleUploadStatus('onchange')}>
                        <img src={TryAgain} alt='Try Again'></img>
                        Try Again
                    </button>
                    </label>
                </div>
            )

        }

    //------------------------------------------------------
}
