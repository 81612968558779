//================================================================
//  Component: Multiple User Search
//================================================================

//  Purpose: This is an input field that allows you to search all LL Users and select a user. Returns an array of selected users.

//  Properties:
//    - selectedUsers = {useReducer, this stores the current selected user, search results and query message}
//    - setSelectedUsers = {useReducer, this is used to set the selected user state}
//    - errorMessage = {string, error message}

//  Example:
//    <MultipleUserSearch
//      selectedUsers={formData}
//      setSelectedUsers={setFormData}
//      errorMessage={errorMessage}
//    ></MultipleUserSearch>    

//================================================================


//Libraries
import React, { useState, useReducer } from 'react';

//Contexts

//Components

//Functions
import QueryDocument from '../../Library/QueryDocument';

//Images

export default function MultipleUserSearch({
    selectedUsers,
    setSelectedUsers,
    errorMessage,
}) {

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------
    const [input, setInput] = useState('');

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Stores list of users based on input
    const [searchList, setSearchList] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'searchResults': [],
            'newUserInput': '',
        }
    );
    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Function used to handle search
    function handleSearch(value) {

        setSearchList({
            'searchResults': [],
            'newUserInput': value,
        })

        // Validate input
        if (searchList?.newUserInput?.length === 0) {

            return setSearchList({ 'queryMessage': 'Please enter an email address.' });

        }

        if (searchList?.newUserInput?.length === 2) {

            return setSearchList({ 'queryMessage': 'Search requires more than two characters.' });

        }

        const query = searchList?.newUserInput?.toLowerCase();

        // Search by characters in email
        QueryDocument('users', [
            ['searcharray', 'array-contains-any', [query]],
            ['usertype', '==', 'lendleaseuser']
        ])
            .then((users) => {

                setSearchList({
                    'searchResults': users,
                    'queryMessage': `Showing ${users?.length} result(s)`,
                });

            })
            .catch((error) => {

                console.log(error);
                setSearchList({ 'queryMessage': 'Oops something went wrong!' });

            });

    }

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col w-full h-full relative'>

            {/* ------------------------------------------------------ */}
            {/*  Users Search Bar                                      */}
            {/* ------------------------------------------------------ */}

            {/* Search Bar */}
            <div className='flex flex-row items-center'>

                {/* Search Input Field */}
                <input
                    className={
                        errorMessage?.length > 0 ?
                            'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border-1 border-solid border-[#DE0000] rounded-[5px]'
                            :
                            searchList?.searchResults?.length > 0 ?
                                'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-tl-[5px] rounded-br-none rounded-tr-[5px] rounded-bl-none'
                                :
                                'w-full h-[42px] px-[15px] py-[10px] bg-[white] shadow-md border border-solid border-[#7c7c7c] rounded-[5px]'
                    }
                    type='text'
                    placeholder='Search user by email address'
                    value={input}
                    onChange={(evt) => {
                        setInput(evt.target.value);
                        handleSearch(evt.target.value)
                    }}
                    onPaste={(evt) => handleSearch(evt.clipboardData.getData('Text'))}
                    autoComplete='no'
                ></input>

            </div>

            {/* Error Message */}
            <p className='font-medium text-sm text-[var(--darkred)] my-2'>{errorMessage}</p>

            {/* Search Results */}
            {
                searchList?.searchResults?.length > 0 &&
                (
                    <div className='absolute w-full top-11 max-h-[150px] overflow-y-auto bg-white shadow-md rounded-tl-none rounded-br-[5px] rounded-tr-none rounded-bl-[5px] border-[1px] border-[solid] border-[#D2D2D2]'>

                        {/* Results */}
                        {
                            searchList?.searchResults.map((user, index) => (
                                <div
                                    key={index}
                                    className='flex flex-row justify-between px-4 py-[13px] cursor-pointer rounded-sm hover:bg-[#F0F0F0]'
                                    onClick={() => {

                                        //Add user to recipient list
                                        const tempList = selectedUsers;

                                        tempList.push({
                                            'searchResults': [],
                                            'newUserInput': user?.emailaddress,
                                            'newUserName': `${user?.givenname} ${user?.surname}`,
                                            'newGivenname': user?.givenname,
                                            'newSurname': user?.surname,
                                        })

                                        setSelectedUsers([...tempList]);

                                        setSearchList({ 'searchResults': [] });

                                        //Clear input field
                                        setInput('');
                                    }}
                                >

                                    {/* User Detail */}
                                    <div className='flex flex-col'>
                                        <p className='m-0 font-medium text-[14px]'>{user?.givenname} {user?.surname}</p>
                                        <p className='m-0 text-[14px]'>{user?.emailaddress} ({user?.jobtitle})</p>
                                    </div>

                                </div>
                            ))
                        }

                        {/* Total */}
                        <div className='text-sm font-medium px-4 py-2 border-t border-t-[#D8D8D8]' hidden={searchList?.queryMessage.length === 0}>
                            {searchList?.queryMessage}
                        </div>

                    </div>
                )
            }

        </div>
    )

    //------------------------------------------------------
}
