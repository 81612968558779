//================================================================
//  Component: CSM Questionnaire
//================================================================

//  Purpose: This contains the 'CSM Questionnaire' on the Initial Assessment tab

//  Properties:
//    - collectionid = {string, Firestore collection id}
//    - documentid = {string, Firestore document id}

//  Example:
//    <CSMQuestionnaire
//      collectionid={collectionid}
//      documentid={documentid}
//    ></CSMQuestionnaire>    

// ==============================================================

// Libraries
import React from 'react'

// Components
import LiveField from '../../../../Components/LiveField/LiveField';
import FileUploadLiveField from '../../../../Components/FileUploadLiveField/FileUploadLiveField';

// CSS
import '../../Task.css';


export default function CSMQuestionnaire({
    collectionid,
    documentid,
    task,
}) {

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <>
        
           {/* ------------- WHAT ------------- */}

           <h5 className='text-[18px] text-teal-600 mb-2'> What </h5>

            {/* What is the application name? */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.applicationname'}
                label={<label className='font-medium'> What is the application name? </label>}
                defaultValue={task?.csmquestionnaire?.applicationname}
                placeholder={'Enter application name'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* What ICT Architecture is this initiative a part of? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.ictarchitecture'}
                label={<label className='font-medium'> What ICT Architecture is this initiative a part of? </label>}
                defaultValue={task?.csmquestionnaire?.ictarchitecture}
                options={[
                    'Investment',
                    'Development',
                    'Construction',
                    'Group',
                    'Enterprise IT/Technology'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Is this application dependent on other technology? */}
            <LiveField
                type={'radio'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.isdependentontech'}
                label={<label className='font-medium'> Is this application dependent on other technology? </label>}
                defaultValue={task?.csmquestionnaire?.isdependentontech}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>    

            {/* If yes, please explain which application(s) */}
            {
                task?.csmquestionnaire?.isdependentontech === 'yes' &&
                <LiveField
                    type={'text'}
                    collectionid={collectionid}
                    documentid={documentid}
                    fieldpath={'csmquestionnaire.dependentapps'}
                    label={<label className='font-medium'> If yes, please explain which application(s). * </label>}
                    defaultValue={task?.csmquestionnaire?.dependentapps}
                    placeholder={'Enter application(s)'}
                    disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
                ></LiveField>
            }

            {/* Is this IDC endorsed? */}
            <LiveField
                type={'radio'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.idcendorsed'}
                label={<label className='font-medium'> Is this IDC endorsed? </label>}
                defaultValue={task?.csmquestionnaire?.idcendorsed}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* If yes, please upload your endorsement */}
            {
                task?.csmquestionnaire?.idcendorsed === 'yes' &&
                <FileUploadLiveField
                    label={<label className='font-medium'> If yes, please upload your endorsement. </label>}
                    defaultValue={task?.csmquestionnaire?.idcendorsement}
                    collectionid={collectionid}
                    documentid={documentid}
                    fieldpath={'csmquestionnaire.idcendorsement'}
                    disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
                ></FileUploadLiveField>
            }

           {/* ------------- WHY ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> Why </h5>

            {/* Why do you need this application? */}
            <LiveField
                type={'multiline'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.applicationneed'}
                label={<label className='font-medium'> Why do you need this application? </label>}
                defaultValue={task?.csmquestionnaire?.applicationneed}
                placeholder={'Enter the need for this application'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* What are the business processes that this application will be used for? */}
            <LiveField
                type={'multiline'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.businessprocesses'}
                label={<label className='font-medium'> What are the business processes that this application will be used for? </label>}
                defaultValue={task?.csmquestionnaire?.businessprocesses}
                placeholder={'List the business processes this application will be used for'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Is it a client mandated software? */}
            <LiveField
                type={'radio'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.isclientmandated'}
                label={<label className='font-medium'> Is it a client mandated software? </label>}
                defaultValue={task?.csmquestionnaire?.isclientmandated}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>  

           {/* ------------- WHO ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> Who </h5>

            {/* What business/function will use this application? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.bufunction'}
                defaultValue={task?.csmquestionnaire?.bufunction}
                label={<label className='font-medium'> What business/function will use this application? </label>}
                options={[
                    'Investment Management',
                    'Development',
                    'Construction',
                    'Corporate Affairs & Marketing',
                    'Finance',
                    'Group Investor Relations',
                    'IT',
                    'Legal',
                    'People and Culture',
                    'Risk and Insurance',
                    'Tax',
                    'Supply Chain',
                    'Treasury',
                    'Sustainability',
                    'EHS'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField> 

            {/* What regions will use this application? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.region'}
                defaultValue={task?.csmquestionnaire?.region}
                label={<label className='font-medium'> What regions will use this application? </label>}
                options={[
                    'AUST',
                    'AMER',
                    'EMEA',
                    'ASIA',
                    'Global'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField> 

            {/* Any specific roles that will use this application? */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.specificroles'}
                label={<label className='font-medium'> Any specific roles that will use this application? </label>}
                defaultValue={task?.csmquestionnaire?.specificroles}
                placeholder={'Enter roles'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* How many users? */}
            <LiveField
                type={'number'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.totalusers'}
                defaultValue={task?.csmquestionnaire?.totalusers}
                label={<label className='font-medium'> How many users? </label>}
                placeholder={'Enter number'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>    

           {/* ------------- TIMING ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> Timing </h5>

            {/* When are you looking to implement this application by? * */}
           <LiveField
                type={'date-string'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.implementationdate'}
                defaultValue={task?.csmquestionnaire?.implementationdate}
                label={<label className='font-medium'> When are you looking to implement this application by? </label>}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

           {/* ------------- IT OPERATIONAL INFORMATION ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> IT Operational Information </h5>

            {/* Who is the business owner for this application? * */}
            <LiveField
                type={'user-search'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.businessowner'}
                label={<label className='font-medium'> Who is the business owner for this application? </label>}
                defaultValue={task?.csmquestionnaire?.businessowner}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* How will this application be managed? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.applicationmanaged'}
                defaultValue={task?.csmquestionnaire?.applicationmanaged}
                label={<label className='font-medium'> How will this application be managed? </label>}
                options={[
                    'IT Managed',
                    'Self Managed'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField> 

            {/* What type of application is this? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.applicationtype'}
                defaultValue={task?.csmquestionnaire?.applicationtype}
                label={<label className='font-medium'> What type of application is this? </label>}
                options={[
                    'Cloud/On Premise',
                    'COTS',
                    'SaaS'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField> 

            {/* Who uses this application? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.primaryapplicationuser'}
                defaultValue={task?.csmquestionnaire?.primaryapplicationuser}
                label={<label className='font-medium'> Who uses this application? </label>}
                options={[
                    'BU',
                    'IDC/Enterprise'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField> 

            {/* Will there be any records in this system that need to comply with the record retention policy? */}
            <LiveField
                type={'multiline'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.recordsforretention'}
                label={<label className='font-medium'> Will there be any records in this system that need to comply with the record retention policy? </label>}
                defaultValue={task?.csmquestionnaire?.recordsforretention}
                placeholder={'Enter records'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* What application self-service forms would you like? */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.selfserviceforms'}
                defaultValue={task?.csmquestionnaire?.selfserviceforms}
                label={<label className='font-medium'> What application self-service forms would you like? </label>}
                options={[
                    "I need access to 'X' application",
                    "I don't need my licenses anymore for 'X' application",
                    'Any other type of requests'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* If other, please describe what forms you would like. * */}
            {
                task?.csmquestionnaire?.selfserviceforms === 'Any other type of requests' &&
                <LiveField
                    type={'text'}
                    collectionid={collectionid}
                    documentid={documentid}
                    fieldpath={'csmquestionnaire.selfserviceformsother'}
                    label={<label className='font-medium'> If other, please describe what forms you would like. </label>}
                    defaultValue={task?.csmquestionnaire?.selfserviceformsother}
                    placeholder={'Enter other forms'}
                    disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
                ></LiveField>
            }

           {/* ------------- COST SUMMARY ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> Cost Summary </h5>

            {/* Set Up Cost  */}
            <LiveField
                type={'number'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.setupcost'}
                label={<label className='font-medium'> Setup Cost </label>}
                defaultValue={task?.csmquestionnaire?.setupcost}
                placeholder={'Enter cost'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* SSO Cost  */}
            <LiveField
                type={'number'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.ssocost'}
                label={<label className='font-medium'> SSO Cost </label>}
                defaultValue={task?.csmquestionnaire?.ssocost}
                placeholder={'Enter cost'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Licensing Cost */}
            <LiveField
                type={'number'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.licensingcost'}
                label={<label className='font-medium'> Licensing Cost </label>}
                defaultValue={task?.csmquestionnaire?.licensingcost}
                placeholder={'Enter cost'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Hosting Model */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.hosting'}
                label={<label className='font-medium'> Hosting Model </label>}
                defaultValue={task?.csmquestionnaire?.hosting}
                options={[
                    'GCP',
                    'AWS',
                    'Azure',
                    'On Premise',
                    'PaaS',
                    'SaaS',
                    'IaaS'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Supported By */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.supportedby'}
                label={<label className='font-medium'> Supported By </label>}
                defaultValue={task?.csmquestionnaire?.supportedby}
                options={[
                    'Accenture',
                    'CapGemini',
                    'Trident',
                    'Business',
                    'Deloitte',
                    'Other',
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

           {/* ------------- CYBER INFORMATION ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> Cyber Information </h5>

            {/* Complexity Level */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.complexitylevel'}
                defaultValue={task?.csmquestionnaire?.complexitylevel}
                label={<label className='font-medium'> Complexity Level </label>}
                options={[
                    'Not Complex',
                    'Less Complex',
                    'Somewhat Complex',
                    'Very Complex'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Impact Level */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.impactlevel'}
                defaultValue={task?.csmquestionnaire?.impactlevel}
                label={<label className='font-medium'> Impact Level </label>}
                options={[
                    'Very Small',
                    'Small',
                    'Medium',
                    'Large',
                    'Very Large'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Personal Information Classification Level */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.personalinfoclassificationlevel'}
                defaultValue={task?.csmquestionnaire?.personalinfoclassificationlevel}
                label={<label className='font-medium'> Personal Information Classification Level </label>}
                options={[
                    'PI 1: Email address and password',
                    'PI 2: Home address and phone numbers',
                    'PI 3: Traceable government info - medicare, drivers license, emergency contact, etc.',
                    'PI 4: Banking details, credit card numbers, etc.',
                    'PI 5: Personal medical information, child information'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Data Classification Label */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.dataclassificationlabel'}
                defaultValue={task?.csmquestionnaire?.dataclassificationlabel}
                label={<label className='font-medium'> Data Classification Label </label>}
                options={[
                    'None',
                    'Public',
                    'Internal',
                    'Personal Information (L1)',
                    'Confidential',
                    'Restricted',
                    'Restricted Government',
                    'Personal Information (L2-5)'
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>  

            {/* Cyber Level */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.cyberlevel'}
                label={<label className='font-medium'> Cyber Level </label>}
                defaultValue={task?.csmquestionnaire?.cyberlevel}
                placeholder={'Enter cyber level'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Will there be any integrations used for this application? */}
            <LiveField
                type={'radio'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.hasintegrations'}
                label={<label className='font-medium'> Will there be any integrations used for this application? </label>}
                defaultValue={task?.csmquestionnaire?.hasintegrations}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* If yes, please list integrations */}
            {
                task?.csmquestionnaire?.hasintegrations === 'yes' &&
                <LiveField
                    type={'multline'}
                    collectionid={collectionid}
                    documentid={documentid}
                    fieldpath={'csmquestionnaire.integrations'}
                    label={<label className='font-medium'> If yes, please list integrations. </label>}
                    defaultValue={task?.csmquestionnaire?.integrations}
                    placeholder={'List integrations'}
                    disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
                ></LiveField>
            }

            {/* Cyber Instant Report Score  */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.cyberinstantreportscore'}
                label={<label className='font-medium'> Cyber Instant Report Score </label>}
                defaultValue={task?.csmquestionnaire?.cyberinstantreportscore}
                options={[
                    'A',
                    'B',
                    'C',
                    'D',
                    'F',
                    'Not Reviewed',
                    'N/A',
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Website Address */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.websiteaddress'}
                label={<label className='font-medium'> Website Address </label>}
                defaultValue={task?.csmquestionnaire?.websiteaddress}
                placeholder={'Enter address'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Does this product meet the Lendlease Cyber Security Requirements? */}
            <LiveField
                type={'radio'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.meetscyberrequirements'}
                label={<label className='font-medium'> Does this product meet the Lendlease Cyber Security Requirements? </label>}
                defaultValue={task?.csmquestionnaire?.meetscyberrequirements}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Can SSO be implemented? */}
            <LiveField
                type={'radio'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.ssoimplemented'}
                label={<label className='font-medium'> Can SSO be implemented? </label>}
                defaultValue={task?.csmquestionnaire?.ssoimplemented}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

           {/* ------------- COMMERCIAL/PROCUREMENT/FINANCE INFORMATION ------------- */}

           <h5 className='text-[18px] text-teal-600 my-3'> Commercial / Procurement / Finance Information </h5>

            {/* Commercial Entity */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.commercialentity'}
                label={<label className='font-medium'> Commercial Entity </label>}
                defaultValue={task?.csmquestionnaire?.commercialentity}
                placeholder={'Enter commercial entity'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Supporting Documents */}
            <FileUploadLiveField
                label={<label className='font-medium'> Supporting Documents </label>}
                defaultValue={task?.csmquestionnaire?.supportingdocs}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.supportingdocs'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></FileUploadLiveField>

            {/* Costing Method */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.costingmethod'}
                label={<label className='font-medium'> Costing Method </label>}
                defaultValue={task?.csmquestionnaire?.costingmethod}
                options={[
                    'IT Budget',
                    'Business Recovered',
                    'Business Budget Direct',
                    'N/A',
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* License Type */}
            <LiveField
                type={'choice'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.licensetype'}
                label={<label className='font-medium'> License Type </label>}
                defaultValue={task?.csmquestionnaire?.licensetype}
                options={[
                    'Subscription',
                    'Perpetual',
                    'Concurrent',
                    'Other',
                ]}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Project Code */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.projectcode'}
                label={<label className='font-medium'> Project Code </label>}
                defaultValue={task?.csmquestionnaire?.projectcode}
                placeholder={'Enter project code'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Task Number */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.tasknumber'}
                label={<label className='font-medium'> Task Number </label>}
                defaultValue={task?.csmquestionnaire?.tasknumber}
                placeholder={'Enter task number'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Expenditure Type */}
            <LiveField
                type={'text'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.expendituretype'}
                label={<label className='font-medium'> Expenditure Type </label>}
                defaultValue={task?.csmquestionnaire?.expendituretype}
                placeholder={'Enter expenditure type'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Finance Manager */}
            <LiveField
                type={'user-search'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.financemanager'}
                label={<label className='font-medium'> Finance Manager </label>}
                defaultValue={task?.csmquestionnaire?.financemanager}
                placeholder={'Enter finance manager'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* ------------- OTHER ------------- */}

            <h5 className='text-[18px] text-teal-600 my-3'> Other </h5>

            {/* Additional Information */}
            <LiveField
                type={'multiline'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.additionalinfo'}
                label={<label className='font-medium'> Additional Information </label>}
                defaultValue={task?.csmquestionnaire?.additionalinfo}
                placeholder={'Enter additional information'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

            {/* Recommendations */}
            <LiveField
                type={'multiline'}
                collectionid={collectionid}
                documentid={documentid}
                fieldpath={'csmquestionnaire.recommendations'}
                label={<label className='font-medium'> Recommendations </label>}
                defaultValue={task?.csmquestionnaire?.recommendations}
                placeholder={'Enter recommendations'}
                disabled={(task?.status === 'Completed' || task?.status === 'Rejected') ? true : false}
            ></LiveField>

        </>
    )

}
