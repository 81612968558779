//================================================================
//  Component: Publish to Inventory Confirmation Modal
//================================================================

//  Purpose: This modal displays any pending CSM tasks prior to the CSM closing a ticket

//  Properties:
//    - modalVisible = {useState, used to determine if the modal is visible and stores the pending CSM tasks}
//    - setModalVisible = {useState, used to set if the modal is visible}
//    - setCloseTicket = {useState, used to determine if the user has acknowledged the pending CSM tasks and would like to proceed with closing the ticket}

//  Example:
//    <PublishInventoryConfirmationModal
//      modalVisible={confirmationModalVisible}
//      setModalVisible={setConfirmationModalVisible}
//      setCloseTicket={setCloseTicket}
//    ></PublishInventoryConfirmationModal> 

//================================================================

//Libraries
import React from 'react';
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router-dom';

//Contexts

//Components

//Functions
import WriteDocument from '../../../../Library/WriteDocument';

//Images
import Warning from '../../../../Components/Images/Icon_Warning_Blue.svg';


export default function PublishInventoryConfirmationModal({
  modalVisible,
  setModalVisible,
  task
}) {

  //------------------------------------------------------
  //  React Router
  //------------------------------------------------------

  const navigate = useNavigate()
  const routerUseLocation = useLocation();

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function handleProceed() {

    WriteDocument('tasks', task?.taskid, { 'stage': 'Publishing' }, true)
    .then(() => {

      // Close Modal and Navigate to Next Stage
      setModalVisible(false);
      navigate(`/${routerUseLocation.pathname.split('/')[1]}/${routerUseLocation.pathname.split('/')[2]}?view=publishtoinventory`);

    })
    .catch((error) => {
      console.log(error);

    });

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (modalVisible === false) return null;
  if (task?.stage !== 'Ready to Publish') return null;
  if (task?.stage === 'Publishing') return null;

  return (
    <div className='Modal-Background'>

      {/* Modal Container */}
      <dialog className='Modal-Container'>

        {/* Header */}
        <div className='flex flex-row gap-3 items-center'>
          <img src={Warning} alt='Warning'></img>
          <h4 className='mr-2'> You are about to proceed to the next stage </h4>
        </div>

        {/* Body */}
        <p className='max-w-[500px]'>You have completed the application assessment phase of this process. If you continue, you will not be able to make any changes.</p>

        {/* Buttons */}
        <div className='flex flex-row gap-2'>

          <button className='Primary-Button' onClick={() => handleProceed()} >
            Proceed
          </button>

          <button className='Secondary-Button' onClick={() => setModalVisible(false)}>
            Cancel
          </button>

        </div>

      </dialog >
    </div >
  )

  //------------------------------------------------------
}