//================================================================
//  Component: Corporate Approval Pane 
//================================================================

//  Purpose: This side pane allows CSMs to create corporate approvals

//  Properties:
//    - paneOpen = { useState, holds a boolean value that opens and closes the sidePane}
//    - setPaneOpen = { useState, updates the boolean value that opens and closes the sidePane}
//    - task = { useState, store the related parent CSM task}

//  Example:
//  
//    <CorporateApprovalPane
//      paneOpen={paneOpen}
//      setPaneOpen={setPaneOpen}
//      task={task}
//    ></CorporateApprovalPane>

//================================================================

//Libraries
import React, { useContext, useState, useReducer, useRef } from 'react';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import MultipleUserSearch from '../../../../../Components/MultipleUserSearch/MultipleUserSearch';

//Functions
import WriteDocument from '../../../../../Library/WriteDocument';
import UploadFile from '../../../../../Library/UploadFile';

//Images
import ClearIcon from '../../../../../Components/Images/Icon_Clear_Teal.svg';
import Loading from '../../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS

export default function CorporateApprovalPane({
  paneOpen,
  setPaneOpen,
  task,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set pane status > 'onload', 'pending', 'error'
  const [paneStatus, setPaneStatus] = useState('onload');

  // Stores email recipients
  const [recipients, setRecipients] = useState([]);

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store form data not saved in react hook forms
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'emailsubject': '',
      'emailbody': '',
      'supportingDoc': undefined,
      'supportingDocError': '',
    }
  );

  //------------------------------------------------------
  //  useRef
  //------------------------------------------------------

  //For storing file input value
  const fileInputRef = useRef(null);

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Submit Handler 
  const handleSubmit = (data) => {

    // Prevent page refresh
    data.preventDefault();

    setPaneStatus('pending');

    const filePromises = [];

    // Get file extension
    const fileType = formData?.supportingDoc?.name?.split('.')[1];
    const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`;

    // New request for each recipient
    recipients.forEach((approver) => {

      const taskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;

      // Save the task id into the approver object
      approver.taskid = taskId;

      // No file attached > Return an empty string
      if (formData.supportingDoc === undefined) return filePromises.push('');

      // Request signed url
      filePromises.push(
        UploadFile(`tasks/${taskId}/${fileId}`, formData.supportingDoc),
      );

    });

    // ----------------------------------------------
    //  Settle Promises > Write to Firestore
    // ----------------------------------------------

    Promise.all(filePromises)
    .then((url) => {

      const writeDocPromises = [];

      // Loop through each approver and update document
      recipients?.forEach((approver, index) => {

        const approvalTask = {
          'taskid': approver?.taskid,
          'requestid': task?.requestid,
          'tasktype': 'Corporate Approval',
          'taskname': formData?.emailsubject,
          'taskdescription': formData?.emailbody,
          'applicationname': task?.applicationname,
          'stage': '',
          'status': 'In Progress',
          'assignmentgroup': 'CSM',
          'requesttype': '',
          'activityfeedvisible': true,
          'approvalhubvisible': true,
          'region': '',
          'projectcode': task?.projectcode,
          'anticipatedcost': task?.anticipatedcost,
          'comments': task?.comments,
          'assignedto': {
            'email': approver?.newUserInput,
            'givenname': approver?.newGivenname,
            'surname': approver?.newSurname
          },
          'assigneddate': new Date(),
          'createddate': new Date(),
          'createdby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
          },
          'lastmodifieddate': new Date(),
          'lastmodifiedby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
          },
          'activityfeed': [
            {
              'activitydate': new Date(),
              'actionedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
                },
              'action': 'created',
              'comments': '',
            },
            {
              'activitydate': new Date(),
              'actionedby': {
                'email': approver?.newUserInput,
                'givenname': approver?.newGivenname,
                'surname': approver?.newSurname
                },
              'action': 'assigned',
              'comments': '',
            }
          ],
          'supportingdocs': url[index].length > 0 ?
            [
              {
                'fileUrl': url[index], // Extract the signed url from the array of promises
                'fileId': fileId,
                'fileName': formData?.supportingDoc?.name,
                'fileType': fileType,
              }
            ] : []
        }

        writeDocPromises.push(
          WriteDocument('tasks', approver?.taskid, approvalTask, false)
        );
        
      })

      return Promise.all(writeDocPromises)
      .then(() => {

        // Reset Form and Close Side Pane
        setFormData({
          'emailsubject': '',
          'emailbody': '',
          'supportingDoc': undefined,
          'supportingDocError': '',
        });
        setRecipients([]);
        setPaneStatus('onload');
        setPaneOpen({ 'corporateApproval': false });

      })
 
    })
    .catch((error) => {

      console.log(error);
      setPaneStatus('error');

    })

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // ==================================================
  //  Hide Side Pane
  // ==================================================

  if (!paneOpen?.corporateApproval) return null;

  // ==================================================
  //  Show Side Pane  
  // ==================================================
  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (paneStatus === 'onload') {

    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container' style={{ width: '65vw' }}>

          {/* Form */}
          <form className='w-full flex flex-col gap-2 overflow-hidden' onSubmit={handleSubmit}>

            {/* Header */}
            <h4 className='mb-0'> Send Email </h4>
            <p className='my-2 text-base'>Additional approvers added to the business request will be notified via email.</p>

            {/* Form */}
            <div className='w-[54vw] overflow-auto flex flex-col'>

              {/* =========================================================== */}
              {/*  1. Send To                                                 */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'>Send To <span className='text-[#C4314B]'>*</span></label>
                <MultipleUserSearch
                  selectedUsers={recipients}
                  setSelectedUsers={setRecipients}
                ></MultipleUserSearch>

                <div className='flex flex-wrap gap-2' hidden={recipients.length === 0}>
                  {
                    recipients?.map((user, index) => (
                      <div key={index} className='flex flex-row gap-2 py-1 px-3 rounded-lg bg-[#DDF3F5] text-[#1C96A3]'>
                        {user.newUserName}

                        {/* Remove Recipient */}
                        <img src={ClearIcon} alt='Clear' className='w-[20px] cursor-pointer' onClick={() => {

                          const tempList = [...recipients];
                          tempList.splice(index, 1);

                          setRecipients(tempList);

                        }} />
                      </div>
                    ))
                  }

                </div>

              </div>

              {/* =========================================================== */}
              {/*  2. Subject                                                 */}
              {/* =========================================================== */}

              <div className='Task-Form-Row mb-3'>
                <label className='font-medium'>Email Subject <span className='text-[#C4314B]'>*</span></label>
                <input
                  className='Input-Field-Text'
                  placeholder='Enter subject line'
                  value={formData?.emailsubject}
                  onChange={(e) => { setFormData({ 'emailsubject': e.target.value }) }}
                  maxLength={72}
                ></input>

              </div>

              {/* =========================================================== */}
              {/*  3. Body                                                    */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'>Email Body <span className='text-[#C4314B]'>*</span></label>
                <textarea
                  className='Input-Field-TextArea'
                  placeholder='Enter email body'
                  value={formData?.emailbody}
                  onChange={(e) => setFormData({ 'emailbody': e.target.value })}
                  maxLength={1000}
                ></textarea>

                <div className='flex self-end text-sm text-gray-400 mb-0'>{formData?.emailbody?.length} / 1000</div>

              </div>

              {/* =========================================================== */}
              {/*  4. Attach Document                                         */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> Attach Document </label>

                <div className='Attach-File-Onload-Container'>
                  <input
                    className={formData?.supportingDocError?.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                    style={{ padding: '0px' }}
                    type='file'
                    accept='.pdf,.doc,.txt,.xlsx,.docx,.csv,.png,.jpg'
                    ref={fileInputRef}
                    onChange={(event) => {

                      const file = event.target.files[0];

                      if (file.size > 20000000) {
                        setFormData({
                          'supportingDocError': 'File size too large. Please upload a file smaller than 20MB.'
                        });
                        return;
                      }

                       // Validate File Types
                       const allowedFileTypes = ['pdf', 'doc', 'txt', 'xlsx', 'docx', 'csv', 'png', 'jpg'];

                       const fileType = file.name.split('.')[1];

                       if (allowedFileTypes.includes(fileType) === false) {
                         return setFormData({ 'supportingDocError': `Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}.` });
                       }
 
                      setFormData({
                        'supportingDocError': '',
                        'supportingDoc': file
                      });

                    }}
                  ></input>
                  <button
                    className='Primary-Button'
                    disabled={formData?.supportingDoc ? false : true}
                    onClick={() => {
                      if (fileInputRef.current) {
                        fileInputRef.current.value = '';
                        setFormData({
                          'supportingDoc': undefined
                        });
                      }
                    }}
                  >Clear</button>
                </div>

                <label className='flex flex-row gap-[5px] text-[13px] text-slate-500 mt-1' hidden={formData?.supportingDocError ? true : false}>
                    Supported file types: PDF, DOC, DOCX, TXT, XLSX, CSV, PNG, JPG
                </label>

                <label className='font-medium text-[#DE0000]' hidden={formData?.supportingDocError.length === 0}>{formData?.supportingDocError}</label>
              </div>

            </div>

            {/* =========================================================== */}
            {/*  4. Buttons                                                 */}
            {/* =========================================================== */}

            <div className='flex flex-row gap-2'>

              <button className='Primary-Button' type='submit' value='submit' disabled={

                // Disable submit if form isn't filled out
                recipients.length === 0 || formData.emailsubject.length === 0 || formData.emailbody.length === 0

              }>
                Send
              </button>

              <button className='Secondary-Button' onClick={() => {

                // Reset Form and Close Side Pane
                setFormData({
                  'emailsubject': '',
                  'emailbody': '',
                  'supportingDoc': undefined,
                  'supportingDocError': '',
                });
                setRecipients([]);
                setPaneOpen({ 'corporateApproval': false });

              }}>
                Cancel
              </button>

            </div>

          </form>

        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Pending
  // ---------------------------------------------------

  else if (paneStatus === 'pending') {
    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container flex justify-center items-center text-center'>
          <img alt='loading-circle-icon' src={Loading}></img>
        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (paneStatus === 'error') {
    return (
      <div className='Pane-Background'>

        {/* Pane Container */}
        <dialog className='Pane-Container'>
          <div className='flex flex-col gap-2 w-full justify-center items-center'>

            <img className='my-2 w-[100px]' src={Error} alt='Error'></img>
            <h4 className='mb-0'>Oops! Something went wrong.</h4>
            <p className='text-center leading-[1.7]'>
              An error occurred while we processed your request.
            </p>
            <button
              className='Primary-Button'
              onClick={() => {
                // Reset Form and Close Side Pane
                setFormData({
                  'emailsubject': '',
                  'emailbody': '',
                  'supportingDoc': undefined,
                  'supportingDocError': '',
                });
                setRecipients([]);
                setPaneOpen({ 'corporateApproval': false });
              }}
            >
              Close
            </button>

          </div>
        </dialog>
      </div>

    )
  }

  //------------------------------------------------------

}