//================================================================
//  Component: View Approver Pane
//================================================================

//  Purpose: This pane will allow users to view an individual approval for a CSM task

//  Properties:
//    - viewPaneApprover = {useReducer, used to store the current approval and determine if the pane is visible}
//    - setViewPaneApprover = {useReducer, used to toggle the visibility of the view approver pane}

//  Example:
//    <ViewApproverPane
//      viewPaneApprover={viewPaneApprover}
//      setViewPaneApprover={setViewPaneApprover}
//    ></ViewApproverPane>    

//================================================================


//Libraries
import React, { useContext, useState, useReducer } from 'react';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import UserSearch from '../../../../../Components/UserSearch/UserSearch';
import HistoryTimeline from '../../../../../Components/HistoryTimeline/HistoryTimeline';

//Functions
import WriteDocument from '../../../../../Library/WriteDocument';

//Images
import Error from '../../../../../Components/Images/Icon_ErrorFatal_Red.svg';
import File from '../../../../../Components/Images/Icon_File_Black.svg';
import Warning from '../../../../../Components/Images/Icon_Warning_DarkYellow.svg';
import Undo from '../../../../../Components/Images/Icon_Undo_Teal.svg';


export default function ViewApproverPane({
  viewPaneApprover,
  setViewPaneApprover
}) {

  //------------------------------------------------------
  //  useContexts and variables
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Used to set pane status > 'onload', 'error'
  const [paneStatus, setPaneStatus] = useState('onload');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store the form inputs
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'newUserInput': '',
      'newGivenname': '',
      'newSurname': '',
      'reassignState': 'view'
    }
  );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Used to reassign the approver
  function handleReassign() {

    const updatedApprovalTask = {
      'lastmodifieddate': new Date(),
      'lastmodifiedby': {
        'email': getUser?.emailaddress,
        'givenname': getUser?.givenname,
        'surname': getUser?.surname
      },
      'activityfeed': viewPaneApprover?.corporateApproval?.activityfeed,
      'assignedto': {
        'email': formData?.newUserInput,
        'givenname': formData?.newGivenname,
        'surname': formData?.newSurname
      },
      'assigneddate': new Date()
    }

    // Update the activity feed
    updatedApprovalTask.activityfeed.push({
      'activitydate': new Date(),
      'actionedby': {
        'email': formData?.newUserInput,
        'givenname': formData?.newGivenname,
        'surname': formData?.newSurname
      },
      'action': 'assigned',
      'comments': '',
    })

    return WriteDocument('tasks', viewPaneApprover?.corporateApproval?.taskid, updatedApprovalTask, true)
      .then(() => {

        // Reset Inputs
        setFormData({
          'newUserInput': '',
          'reassignState': 'view'
        })

        // Close Pane
        setViewPaneApprover({ 'corporateApproval': undefined });

      })
      .catch((error) => {
        console.log(error);
        setPaneStatus('error');

      })

  }

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  if (viewPaneApprover?.corporateApproval === undefined) return null;

  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (paneStatus === 'onload') {
    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container'>

          <div className='flex flex-col gap-3'>

            {/* ------------------------------- */}
            {/*  Approval Summary               */}
            {/* ------------------------------- */}

            <div className='Pane-Form-Container h-[60%] overflow-x-hidden'>

              {/* ===================================== */}
              {/*  Header                               */}
              {/* ===================================== */}

              <h4 className='px-[5%] py-[2.5%] mb-0 text-[22px]'> Corporate Approval | {viewPaneApprover?.corporateApproval?.applicationname} </h4>
              <hr className='m-0'></hr>

              {/* ===================================== */}
              {/*  Summary                              */}
              {/* ===================================== */}

              <div className='flex flex-col gap-2 px-[5%] py-[2%]'>

                {/* ------------------------------- */}
                {/*  Approver                       */}
                {/* ------------------------------- */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'> Approver </label>
                  {
                    formData?.reassignState === 'view' ?

                      // View Current Approver
                      (
                        <div className='flex flex-row justify-between border border-[#D8D8D8] px-3 py-2 rounded-[5px]'>
                          <div className='flex flex-col'>
                            <p className='m-0'>{viewPaneApprover?.corporateApproval?.assignedto?.givenname} {viewPaneApprover?.corporateApproval?.assignedto?.surname}</p>
                            <p className='text-[13px] m-0 text-slate-600'>{viewPaneApprover?.corporateApproval?.assignedto?.email}</p>
                          </div>
                          <button className='Primary-Button' disabled={viewPaneApprover?.corporateApproval?.status === 'Completed' || viewPaneApprover?.corporateApproval?.status === 'Cancelled'} onClick={() => setFormData({ 'reassignState': 'edit' })}>
                            Reassign
                          </button>
                        </div>
                      )
                      :
                      // Reassign Approver
                      (
                        <div className='flex flex-col gap-2'>

                          {/* Input Field */}
                          <div className='flex flex-col gap-2 justify-between bg-[#F5F5F5] border border-[#D8D8D8] p-3 rounded-[5px]'>
                            <label className='m-0'>Please enter email address to reassign: </label>

                            <div className='flex flex-row gap-2 items-center'>

                              {/* User Search Input Field */}
                              <UserSearch
                                selectedUser={formData}
                                setSelectedUser={setFormData}
                              ></UserSearch>

                              {/* Proceed Button */}
                              <button className='Primary-Button' disabled={formData?.newUserInput?.length === 0} onClick={() => handleReassign()}>Proceed</button>

                              {/* Undo Button */}
                              <img className='cursor-pointer' src={Undo} alt='undo' onClick={() => setFormData({ 'reassignState': 'view' })}></img>

                            </div>

                          </div>

                          {/* Warning Label */}
                          <div className='flex flex-row gap-3 bg-[#FBF6D9] border border-[#F2E384] text-[#835C00] rounded-md font-medium px-3 py-2 text-[15px]'>
                            <img src={Warning} alt='warning'></img>
                            Proceeding will notify the original approver their approval is no longer required and send a new approval request to the reassigned approver.
                          </div>

                        </div>
                      )
                  }

                </div>

                {/* ------------------------------- */}
                {/*  Email Subject                  */}
                {/* ------------------------------- */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'>Email Subject</label>
                  <input
                    className='Input-Field-Text'
                    type='text'
                    value={viewPaneApprover?.corporateApproval?.taskname}
                    disabled
                  ></input>
                </div>

                {/* ------------------------------- */}
                {/*  Email Body                     */}
                {/* ------------------------------- */}

                <div className='FormComponent-Row'>
                  <label className='font-medium'> Email Body</label>
                  <textarea
                    className='Input-Field-TextArea overflow-y-auto'
                    style={{ height: '200px' }}
                    type='text'
                    value={viewPaneApprover?.corporateApproval?.taskdescription}
                    disabled
                  ></textarea>

                </div>

                {/* ------------------------------- */}
                {/*  Document                       */}
                {/* ------------------------------- */}

                <div className='FormComponent-Row mb-[2%]'>
                  <label className='font-medium mb-1'> Attached Document </label>
                  {
                    viewPaneApprover?.corporateApproval?.supportingdocs?.length === 0 ?
                      <div>
                        No documents attached.
                      </div>
                      :
                      <div className='flex flex-row gap-2 items-center rounded-md border border-[#D8D8D8] p-2'>
                        <img src={File} alt='file'></img>
                        <a href={viewPaneApprover?.corporateApproval?.supportingdocs[0]?.fileUrl} target='_blank' rel='noopener noreferrer'> {viewPaneApprover?.corporateApproval?.supportingdocs[0]?.fileName} </a>
                      </div>
                  }

                </div>

              </div>

            </div>

            {/* ------------------------------- */}
            {/*  Approval History               */}
            {/* ------------------------------- */}

            <div className='Pane-Form-Container h-[40%] overflow-x-hidden'>

              {/* ===================================== */}
              {/*  Header                               */}
              {/* ===================================== */}

              <h4 className='px-[5%] py-[2.5%] mb-0 text-[22px]'> History </h4>
              <hr className='m-0'></hr>

              {/* ===================================== */}
              {/*  Timeline                             */}
              {/* ===================================== */}

              <div className='w-full max-w-3xl mx-auto my-3'>

                {
                  viewPaneApprover?.corporateApproval?.activityfeed?.toReversed().map((item, index) => (
                    <HistoryTimeline
                      item={item}
                      key={index}
                    ></HistoryTimeline>
                  ))
                }

              </div>

            </div>

            {/* ------------------------------- */}
            {/*  Submit Buttons                 */}
            {/* ------------------------------- */}

            <div>
              <button className='Primary-Button' onClick={() => {
                setFormData({
                  'newUserInput': '',
                  'reassignState': 'view'
                })
                setViewPaneApprover({ 'corporateApproval': undefined });
              }}>
                Close
              </button>
            </div>

          </div>

        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (paneStatus === 'error') {
    return (
      <div className='Pane-Background'>

        {/* Pane Container */}
        <dialog className='Pane-Container'>
          <div className='flex flex-col gap-2 w-full justify-center items-center'>

            <img className='my-2 w-[100px]' src={Error} alt='Error'></img>
            <h4 className='mb-0'>Oops! Something went wrong.</h4>
            <p className='text-center leading-[1.7]'>
              An error occurred while we processed your request.
            </p>
            <button
              className='Primary-Button'
              onClick={() => {
                setPaneStatus('onload');
                setViewPaneApprover({ 'corporateApproval': undefined });
              }}
            >
              Close
            </button>

          </div>
        </dialog>
      </div>

    )
  }

  //------------------------------------------------------
}
