//================================================================
//  Component: Publish to Inventory Tab
//================================================================

//  Purpose: This contains the form for publishing the application to inventoru

//  Properties:
//    - task = {An object, contains the business request task details}

//  Example:
//    <PublishToInventoryTab
//      task={task}
//    ></PublishToInventoryTab>    

// ==============================================================

// Libraries
import React, { useState, useContext, useEffect } from 'react';
import { useForm } from 'react-hook-form';

// Contexts
import { GetUser } from '../../../../Library/GlobalContexts';

// Components
import LookUpRHF from '../../../../Components/LookUpRHF/LookUpRHF';
import PublishInventoryConfirmationModal from '../Modals/PublishInventoryConfirmationModal';
import PageComponent from '../../../../Components/PageComponent/PageComponent';

//Functions
import WriteDocument from '../../../../Library/WriteDocument';
import QueryDocument from '../../../../Library/QueryDocument';
import ConvertDate from '../../../../Library/ConvertDate';

// Images
import Complete from '../../../../Components/Images/Icon_Complete_Green.svg';
import Link from '../../../../Components/Images/Icon_Link_Blue.svg';

// CSS
import '../../Task.css';

export default function PublishToInventoryTab({
    task
}) {

    //------------------------------------------------------
    //  useContexts
    //------------------------------------------------------

    const getUser = useContext(GetUser);

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to save page status > 'pending', 'onload'
    const [pageStatus, setPageStatus] = useState('onload');

    // Used to determine if the confirmation modal is visible (stores the pending CSM tasks)
    const [pendingCSMTasks, setPendingCSMTasks] = useState([]);

    // Used to determine if the CSM has acknowledged the pending CSM tasks and would like to proceed with closing the ticket
    const [closeTicket, setCloseTicket] = useState(false);

    // --------------------------------------------------
    //  Form State
    // --------------------------------------------------

    const { register, handleSubmit, setValue, clearErrors, formState: { errors } } = useForm();

    // --------------------------------------------------
    //  Functions
    // --------------------------------------------------

    // Function used to group an array of objects
    const groupBy = (array, property) =>
        array.reduce((acc, obj) => {
            const key = obj[property];
            acc[key] = [...(acc[key] || []), obj];
            return acc;
        }, {}
        );

    // Submit Handler > Close Ticket & Add Application to Inventory
    const onSubmit = (formData) => {

        setPageStatus('pending');

        // ===============================================
        //  Check for any pending CSM tasks > Show Modal
        // ===============================================

        const nonDiligenceGovernanceTasks = [
            'Business Request',
            'Line Manager Approval',
            'Finance Manager Approval',
            'Instant Vendor Risk Report'
        ];

        QueryDocument('tasks', [
            ['requestid', '==', task?.requestid],
            ['status', '==', 'In Progress'],
            ['tasktype', 'not-in', nonDiligenceGovernanceTasks],
        ])
            .then((results) => {

                // Pending CSM tasks
                if (results.length > 0 && closeTicket === false) {

                    // Group By Task Type > Convert to Array
                    const tasksGroupByTaskType = Object.entries(groupBy(results, 'tasktype'));

                    // Show Confirmation Modal
                    return setPendingCSMTasks(tasksGroupByTaskType);

                };

                // ==========================================
                //  Create a searcharray for lookup fields
                // ==========================================

                const searchArray = [];
                let previousValue = '';
                [...formData.applicationname]?.forEach((value, index) => {

                    // Skip the first character
                    if (index === 0) {

                        previousValue = value;
                        return searchArray.push(value.toLowerCase());

                    }

                    // Create an array of each character
                    searchArray.push(`${previousValue}${value}`.toLowerCase());
                    previousValue = `${previousValue}${value}`.toLowerCase();

                });

                // ===========================================
                //  Look Up Agreement
                // ===========================================

                return QueryDocument('agreements', [['requestid', 'array-contains', task?.requestid]])
                    .then((agreement) => {

                        // ===========================================
                        //  Write Application Doc to Firestore
                        // ===========================================

                        const docPromises = [];

                        const applicationId = `${btoa(formData.applicationname)}-${Date.now()}`;

                        const documentObject = {
                            'applicationid': applicationId,
                            'requestid': [task?.requestid],
                            'applicationstatus': formData.applicationstatus,
                            'createdby': getUser.emailaddress,
                            'createdbyname': `${getUser.givenname} ${getUser.surname}`,
                            'createddate': new Date(),
                            'lastmodifieddate': new Date(),
                            'lastmodifiedby': {
                                'email': getUser?.emailaddress,
                                'givenname': getUser?.givenname,
                                'surname': getUser?.surname
                            },
                            'agreements': [
                                {
                                    'agreementid': agreement?.length > 0 ? agreement[0]?.agreementid : '',
                                    'poanumber': agreement?.length > 0 ? agreement[0]?.poanumber : ''
                                }
                            ],
                            'applicationname': formData.applicationname,
                            'searcharray': searchArray,
                            'applicationdescription': formData.applicationdescription,
                            'vendor': {
                                'vendorname': '',
                                'vendorid': '',
                            },
                            'decommissiondate': '',
                            'itowner': [
                                formData.itowner
                            ],
                            'businessowner': [
                                formData.businessowner
                            ],
                            'icon': '',
                            'defaultapp': [],
                            'usedby': [],
                            'subscribed': [],
                            'unsubscribed': [],
                            'nooflicensespurchased': '0',
                            'type': '',
                            'deploymentname': '',
                            'deploymenttype': '',
                            'capabilityfamily': [],
                            'capabilitylevel3': '',
                            'supportedby': formData.supportedby,
                            'supportlevelinternal': [],
                            'supportlevelexternal': [],
                            'ssoimplemented': '',
                            'mfaimplemented': '',
                            'typeofcost': '',
                            'budgetowner': [],
                            'projectcode': '',
                            'tasknumber': '',
                            'expendituretype': '',
                            'licencetype': '',
                            'costallocationcurrency': '',
                            'bcp': '',
                            'rpo': '',
                            'rto': '',
                            'linktodr': '',
                            'region': formData.region,
                            'businessunit': formData.businessunit,
                            'applicationcategories': formData.applicationcategories,
                            'primarybeneficiary': task?.primarybeneficiary,
                        };

                        docPromises.push(
                            WriteDocument('applications', documentObject.applicationid, documentObject, false)
                        );

                        // ===========================================
                        //  Close the Business Request Ticket
                        // ===========================================

                        const updatedDoc = {
                            'applicationid': applicationId,
                            'status': 'Completed',
                            'lastmodifieddate': new Date(),
                            'lastmodifiedby': {
                                'email': getUser?.emailaddress,
                                'givenname': getUser?.givenname,
                                'surname': getUser?.surname
                            },
                            'activityfeed': task?.activityfeed,
                            'stage': 'Completed',
                        }

                        // Update the activity feed
                        updatedDoc.activityfeed.push({
                            'activitydate': new Date(),
                            'actionedby': {
                                'email': getUser?.emailaddress,
                                'givenname': getUser?.givenname,
                                'surname': getUser?.surname
                            },
                            'action': 'completed',
                            'comments': '',
                        })

                        docPromises.push(
                            WriteDocument('tasks', task.taskid, updatedDoc, true)
                        );

                        // ===========================================
                        //  Resolve Promises
                        // ===========================================

                        return Promise.all(docPromises)
                            .then(() => {

                                setPageStatus('onload');
                                setPendingCSMTasks([]);

                            })

                    })

            })
            .catch((errors) => {
                console.log('error', errors);

            })

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    //  Onload > Set all the default values on the form
    useEffect(() => {

        if (task === undefined) return;

        // Task 'In Progress' > Set Default Values
        if (task?.status !== 'Completed') {

            setValue('applicationname', task?.applicationname);
            setValue('businessowner', task?.csmquestionnaire?.businessowner?.email);
            setValue('supportedby', task?.csmquestionnaire?.supportedby)

            return
        }

        // eslint-disable-next-line
    }, [task]);

    // Submit Form is the user clicks 'Proceed' on the modal
    useEffect(() => {

        if (closeTicket === false) return;
        if (task?.status === 'Completed') return;

        // Trigger Submit
        handleSubmit(onSubmit);

        // eslint-disable-next-line
    }, [closeTicket])

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <PageComponent
            status={pageStatus}
            body={
                <div className='flex flex-col gap-2 p-0'>

                    {
                        task?.status !== 'Completed' ?

                            // ====================================================== //
                            //    Task In Progress                                    //
                            // ====================================================== //

                            (
                                <div className='Task-Section-Container'>

                                    {/* Header */}
                                    <h4 className='text-[20px] px-[3%] py-[18px] m-0'>Publish Application</h4>
                                    <hr className='m-0'></hr>

                                    {/* Form */}
                                    <form className='w-full flex flex-col gap-2 overflow-hidden p-[3%]' onSubmit={handleSubmit(onSubmit)}>

                                        <p className='m-0'>
                                            Following the completion of this request, the requestor will be notified of the outcome.
                                        </p>

                                        {/* =========================================================== */}
                                        {/*  1. Application Name                                        */}
                                        {/* =========================================================== */}

                                        <div className='Task-Form-Row'>
                                            <label className='font-medium'> 1. Application Name <span className='text-[#C4314B]'>*</span></label>
                                            <input
                                                className={errors.applicationname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                                                {
                                                ...register('applicationname', {
                                                    required: 'This field is required'
                                                }
                                                )
                                                }
                                                placeholder='Enter application name'
                                                type='text'
                                            ></input>

                                            {errors.applicationname && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.applicationname?.message}</p>}
                                        </div>

                                        {/* =========================================================== */}
                                        {/*  2. Application Description                                 */}
                                        {/* =========================================================== */}

                                        <div className='Task-Form-Row'>
                                            <label className='font-medium'> 2. Application Description <span className='text-[#C4314B]'>*</span></label>
                                            <textarea
                                                className={errors.applicationdescription ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                                                {
                                                ...register('applicationdescription', {
                                                    required: 'This field is required'
                                                })
                                                }
                                                placeholder='Enter a description'
                                            ></textarea>

                                            {errors.applicationdescription && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.applicationdescription?.message}</p>}
                                        </div>

                                        {/* =========================================================== */}
                                        {/*  3. Application Status                                      */}
                                        {/* =========================================================== */}

                                        <div className='Task-Form-Row'>
                                            <label className='font-medium'> 3. Application Status <span className='text-[#C4314B]'>*</span> </label>
                                            <select
                                                className='Input-Field-Select'
                                                {
                                                ...register(
                                                    'applicationstatus', {
                                                    required: 'This is a required field.'
                                                }
                                                )
                                                }
                                            >
                                                <option value='Active'>Active</option>
                                                <option value='Divest'>Divest</option>
                                                <option value='Dormant'>Dormant</option>
                                                <option value='Archived'>Archived</option>
                                                <option value='Decommissioned'>Decommissioned</option>
                                            </select>

                                            {errors.applicationstatus && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.applicationstatus?.message}</p>}
                                        </div>

                                        {/* =========================================================== */}
                                        {/*  4. Business Owner                                          */}
                                        {/* =========================================================== */}

                                        <div className='Task-Form-Row'>
                                            <label className='font-medium'> 4. Business Owner <span className='text-[#C4314B]'>*</span> </label>
                                            <LookUpRHF
                                                fieldId={'businessowner'}
                                                collectionId={'users'}
                                                dataField={'emailaddress'}
                                                placeholder={'Search user by email address'}
                                                required={true}
                                                register={register}
                                                setValue={setValue}
                                                errors={errors.businessowner}
                                                dataFieldSecondary={'givenname'}
                                                dataFieldTertiary={'surname'}
                                                additionalQueries={['usertype', '==', 'lendleaseuser']}
                                                clearErrors={clearErrors}
                                            ></LookUpRHF>
                                        </div>

                                        {/* =========================================================== */}
                                        {/*  5. IT Owner                                                */}
                                        {/* =========================================================== */}

                                        <div className='Task-Form-Row'>
                                            <label className='font-medium'> 5. IT Owner <span className='text-[#C4314B]'>*</span> </label>
                                            <LookUpRHF
                                                fieldId={'itowner'}
                                                collectionId={'users'}
                                                dataField={'emailaddress'}
                                                placeholder={'Search user by email address'}
                                                required={true}
                                                register={register}
                                                setValue={setValue}
                                                errors={errors.itowner}
                                                dataFieldSecondary={'givenname'}
                                                dataFieldTertiary={'surname'}
                                                additionalQueries={['usertype', '==', 'lendleaseuser']}
                                                clearErrors={clearErrors}
                                            ></LookUpRHF>
                                        </div>

                                        {/* =========================================================== */}
                                        {/*  6. Supported By                                            */}
                                        {/* =========================================================== */}

                                        <div className='Task-Form-Row'>
                                            <label className='font-medium'> 6. Supported By <span className='text-[#C4314B]'>*</span> </label>
                                            <select
                                                className='Input-Field-Select'
                                                {
                                                ...register(
                                                    'supportedby', {
                                                    required: 'This is a required field.'
                                                }
                                                )
                                                }
                                            >
                                                <option hidden value=''>-</option>
                                                <option value='Accenture'>Accenture</option>
                                                <option value='CapGemini'>CapGemini</option>
                                                <option value='Trident'>Trident</option>
                                                <option value='Business'>Business</option>
                                                <option value='Deloitte'>Deloitte</option>
                                                <option value='Other'>Other</option>
                                            </select>

                                            {errors.supportedby && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.applicationstatus?.message}</p>}

                                        </div>

                                        {/* =========================================================== */}
                                        {/*  7. Region                                                  */}
                                        {/* =========================================================== */}

                                        <div className='FormComponent-Row'>
                                            <label className='font-medium'>7. Region <span className='text-[#C4314B]'>*</span></label>
                                            <p className='text-sm text-slate-600 mb-1 p-0'>To select more than one, press Ctrl and select your options.</p>
                                            <select
                                                className={errors.region ? 'Input-Field-MultiSelect-Error' : 'Input-Field-MultiSelect'}
                                                {...register('region', {
                                                    required: 'This field is required',
                                                })}
                                                multiple
                                            >
                                                <option hidden value=''>-</option>
                                                <option value='Australia'>Australia</option>
                                                <option value='China'>China</option>
                                                <option value='India'>India</option>
                                                <option value='Italy'>Italy</option>
                                                <option value='Japan'>Japan</option>
                                                <option value='Malaysia'>Malaysia</option>
                                                <option value='New Zealand'>New Zealand</option>
                                                <option value='Singapore'>Singapore</option>
                                                <option value='United Kingdom'>United Kingdom</option>
                                                <option value='United States'>United States</option>
                                            </select>

                                            {errors.region && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.region?.message}</p>}

                                        </div>

                                        {/* =========================================================== */}
                                        {/*  8. Business Unit                                           */}
                                        {/* =========================================================== */}

                                        <div className='FormComponent-Row'>
                                            <label className='font-medium'>8. Business Unit <span className='text-[#C4314B]'>*</span></label>
                                            <p className='text-sm text-slate-600 mb-1 p-0'>To select more than one, press Ctrl and select your options.</p>
                                            <select
                                                className={errors.businessunit ? 'Input-Field-MultiSelect-Error' : 'Input-Field-MultiSelect'}
                                                {...register('businessunit', {
                                                    required: 'This field is required',
                                                })}
                                                multiple
                                            >
                                                <option hidden value=''>-</option>
                                                <option value='AU - Capella'>AU - Capella</option>
                                                <option value='AU - Communities'>AU - Communities</option>
                                                <option value='AU - Construction'>AU - Construction</option>
                                                <option value='AU - Defence'>AU - Defence</option>
                                                <option value='AU - Development'>AU - Development</option>
                                                <option value='Group - CA&M'>Group - CA&M</option>
                                                <option value='Group - EH&S'>Group - EH&S</option>
                                                <option value='Group - Finance'>Group - Finance</option>
                                                <option value='Group - IT'>Group - IT</option>
                                                <option value='Group - Internal Audit'>Group - Internal Audit</option>
                                                <option value='Group - Legal'>Group - Legal</option>
                                                <option value='Group - People and Culture'>Group - People and Culture</option>
                                                <option value='Group - Risk'>Group - Risk</option>
                                                <option value='Group - Sustainability'>Group - Sustainability</option>
                                                <option value='Group - Treasury'>Group - Treasury</option>
                                                <option value='Operations'>Operations</option>
                                                <option value='Podium'>Podium</option>
                                            </select>

                                            {errors?.businessunit && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors?.businessunit?.message}</p>}

                                        </div>

                                        {/* =========================================================== */}
                                        {/*  9. Application Categories                                  */}
                                        {/* =========================================================== */}

                                        <div className='FormComponent-Row'>
                                            <label className='font-medium'>9. Application Categories <span className='text-[#C4314B]'>*</span></label>
                                            <p className='text-sm text-slate-600 mb-1 p-0'>To select more than one, press Ctrl and select your options.</p>
                                            <select
                                                className={errors.applicationcategories ? 'Input-Field-MultiSelect-Error' : 'Input-Field-MultiSelect'}
                                                {...register('applicationcategories', {
                                                    required: 'This field is required',
                                                })}
                                                multiple
                                            >
                                                <option hidden value=''>-</option>
                                                <option value='IT Business Management'>IT Business Management</option>
                                                <option value='IT Infrastructure Services Management'>IT Infrastructure Services Management</option>
                                                <option value='IT Service Management'>IT Service Management</option>
                                                <option value='IT Service Operations'>IT Service Operations</option>
                                                <option value='Analytics & Reporting'>Analytics & Reporting</option>
                                                <option value='Application Hosting'>Application Hosting</option>
                                                <option value='Asset Maintenance & Operations'>Asset Maintenance & Operations</option>
                                                <option value='Brand Strategy'>Brand Strategy</option>
                                                <option value='Business Automation'>Business Automation</option>
                                                <option value='Business Continuity'>Business Continuity</option>
                                                <option value='Client Management'>Client Management</option>
                                                <option value='Collaboration'>Collaboration</option>
                                                <option value='Compliance Management'>Compliance Management</option>
                                                <option value='Construction Management'>Construction Management</option>
                                                <option value='Corporate Social Responsibility Management'>Corporate Social Responsibility Management</option>
                                                <option value='Cost Planning'>Cost Planning</option>
                                                <option value='Defect & Warranty Management'>Defect & Warranty Management</option>
                                                <option value='Delivery Management'>Delivery Management</option>
                                                <option value='Design & Modelling'>Design & Modelling</option>
                                                <option value='Design Collaboration'>Design Collaboration</option>
                                                <option value='Design Procurement Management'>Design Procurement Management</option>
                                                <option value='Development Management'>Development Management</option>
                                                <option value='Executive & Strategy'>Executive & Strategy</option>
                                                <option value='Facilities Management'>Facilities Management</option>
                                                <option value='Financial Management'>Financial Management</option>
                                                <option value='Funds Management'>Funds Management</option>
                                                <option value='Health, Safety & Wellbeing'>Health, Safety & Wellbeing</option>
                                                <option value='Human Capital Management'>Human Capital Management</option>
                                                <option value='Information Management'>Information Management</option>
                                                <option value='Legal Service Management'>Legal Service Management</option>
                                                <option value='Marketing'>Marketing</option>
                                                <option value='Marketing & Communications'>Marketing & Communications</option>
                                                <option value='Network Services'>Network Services</option>
                                                <option value='Pipeline Management'>Pipeline Management</option>
                                                <option value='Policy Management'>Policy Management</option>
                                                <option value='Product Development'>Product Development</option>
                                                <option value='Productivity & Collaboration'>Productivity & Collaboration</option>
                                                <option value='Project Delivery Accounting'>Project Delivery Accounting</option>
                                                <option value='Project Management'>Project Management</option>
                                                <option value='Project Safety Management'>Project Safety Management</option>
                                                <option value='Property Management'>Property Management</option>
                                                <option value='Resource Planning Management'>Resource Planning Management</option>
                                                <option value='Risk Management'>Risk Management</option>
                                                <option value='Sales Management'>Sales Management</option>
                                                <option value='Scheduling'>Scheduling</option>
                                                <option value='Security Services'>Security Services</option>
                                                <option value='Shareholder Management'>Shareholder Management</option>
                                                <option value='Stakeholder Management'>Stakeholder Management</option>
                                                <option value='Subcontractor & Supplier Management'>Subcontractor & Supplier Management</option>
                                                <option value='Subcontractor Procurement Management'>Subcontractor Procurement Management</option>
                                                <option value='Supply Chain Management'>Supply Chain Management</option>
                                                <option value='System Integration'>System Integration</option>
                                                <option value='Technology Management'>Technology Management</option>
                                                <option value='Treasury Management'>Treasury Management</option>
                                            </select>

                                            {errors?.applicationcategories && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors?.applicationcategories?.message}</p>}

                                        </div>

                                        {/* =========================================================== */}
                                        {/*  Submit Button                                              */}
                                        {/* =========================================================== */}

                                        <button className='Primary-Button w-fit mt-4' type='submit' value='submit'>
                                            Submit
                                        </button>

                                    </form>

                                    {/* Confirmation Modal */}
                                    <PublishInventoryConfirmationModal
                                        modalVisible={pendingCSMTasks}
                                        setModalVisible={setPendingCSMTasks}
                                        setCloseTicket={setCloseTicket}
                                    ></PublishInventoryConfirmationModal>

                                </div>
                            )
                            :

                            // ====================================================== //
                            //    Task Completed                                      //
                            // ====================================================== //
                            (
                                <div className='Task-Section-Container'>

                                    {/* Header */}
                                    <h4 className='text-[20px] px-[3%] py-[18px] m-0'> Confirmation </h4>
                                    <hr className='m-0'></hr>

                                    {/* Body */}
                                    <div className='w-full flex flex-col gap-3 overflow-hidden p-[3%]'>
                                        <div className='grid grid-cols-[30px_1fr] gap-3'>

                                            {/* Complete Icon */}
                                            <img src={Complete} alt='complete' width='25px' height='25px'></img>

                                            {/* Link */}
                                            <div className='flex flex-col gap-2'>

                                                <p className='mb-0'>
                                                    <b className=' capitalize'>{task?.lastmodifiedby?.givenname} {task?.lastmodifiedby?.surname}</b> has fulfilled this business request on {ConvertDate(task?.lastmodifieddate)}.
                                                </p>

                                                <div className='flex flex-row gap-2 font-medium cursor-pointer'>
                                                    <a href={`https://inventory.store.lendlease.com/inventory/application/${task?.applicationid}?view=overview`} target='_blank' rel='noreferrer'>
                                                        View application in inventory
                                                    </a>
                                                    <img src={Link} alt='link'></img>
                                                </div>

                                            </div>

                                        </div>
                                    </div>

                                </div>
                            )
                    }

                </div>
            }
        ></PageComponent>
    )

}
