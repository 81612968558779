//================================================================
//  Component: Commercial Task Pane 
//================================================================

//  Purpose: This side pane allows CSMs to create commercial tasks

//  Properties:
//    - paneOpen = { useState, holds a boolean value that opens and closes the sidePane}
//    - setPaneOpen = { useState, updates the boolean value that opens and closes the sidePane}
//    - task = { useState, store the related parent CSM task}

//  Example:
//  
//    <CommercialTaskPane
//      paneOpen={paneOpen}
//      setPaneOpen={setPaneOpen}
//      task={task}
//    ></CommercialTaskPane>

//================================================================

//Libraries
import React, { useContext, useState, useReducer, useEffect } from 'react';
import { useForm } from 'react-hook-form';

//Contexts
import { GetUser } from '../../../../../Library/GlobalContexts';

//Components
import LookUpRHF from '../../../../../Components/LookUpRHF/LookUpRHF';
import SelectVendorField from '../SelectVendorField';

//Functions
import WriteDocument from '../../../../../Library/WriteDocument';
import UploadFile from '../../../../../Library/UploadFile';

//Images
import FileIcon from '../../../../../Components/Images/Icon_File_Black.svg'
import Loading from '../../../../../Components/Images/Image_Loading_Ripple.svg';

//CSS

export default function CommercialTaskPane({
  paneOpen,
  setPaneOpen,
  task,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  // --------------------------------------------------
  //  Form State
  // --------------------------------------------------

  const { register, handleSubmit, setValue, reset, clearErrors, formState: { errors } } = useForm();

  //------------------------------------------------------
  //  useState
  //------------------------------------------------------

  // Used to set pane status > 'onload', 'pending', 'error'
  const [paneStatus, setPaneStatus] = useState('onload');

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

  // Used to store form data not saved in react hook forms
  const [formData, setFormData] = useReducer(
    (state, newState) => ({ ...state, ...newState }),
    {
      'supportingDocs': [],
      'supportingDocsError': '',
    }
  );

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  // Submit Handler 
  const onSubmit = (data) => {

    setPaneStatus('pending');

    // Generate IDs
    const newTaskId = `task-${Date.now().toString()}${Math.floor(Math.random() * (9999 - 1000 + 1) + 1000)}`;
    const poaNumber = `FY24-${Math.floor(Date.now() / 1000).toString().slice(-6)}`;

    const filePromises = [];

    // Check if attachment exists > Upload File
    formData.supportingDocs.forEach((document) => {

      filePromises.push(
        UploadFile(`tasks/${newTaskId}/${document?.fileId}`, document?.fileObject)
      );

    })

    // ----------------------------------------------
    //  Settle Promises > Write to Firestore
    // ----------------------------------------------
    Promise.all(filePromises)
      .then((urls) => {

        const updatedDocs = [];

        // Loop through docs > Add URL
        formData?.supportingDocs.forEach((document, index) => {
          updatedDocs.push({
            'fileUrl': urls[index],
            'fileId': document?.fileId,
            'fileName': document?.fileName,
            'fileType': document?.fileType
          })
        })

        const commercialTask = {
          'taskid': newTaskId,
          'requestid': task?.requestid,
          'tasktype': 'Commercial Review',
          'taskname': 'Digital Commercial Request',
          'taskdescription': `New Agreement for ${task?.applicationname}`,
          'applicationname': task?.applicationname,
          'stage': 'Triage',
          'status': 'Not Started',
          'assignmentgroup': 'Commercial',
          'requesttype': data.requesttype,
          'activityfeedvisible': true,
          'approvalhubvisible': false,
          'region': data.region,
          'assignedto': {
            'email': 'Unassigned',
            'givenname': '',
            'surname': ''
          },
          'assigneddate': '',
          'createddate': new Date(),
          'createdby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
          },
          'lastmodifieddate': new Date(),
          'lastmodifiedby': {
            'email': getUser?.emailaddress,
            'givenname': getUser?.givenname,
            'surname': getUser?.surname
          },
          'activityfeed': [
            {
              'activitydate': new Date(),
              'actionedby': {
                'email': getUser?.emailaddress,
                'givenname': getUser?.givenname,
                'surname': getUser?.surname
              },
              'action': 'created',
              'comments': '',
            }
          ],

          // Commercial Fields
          'poanumber': poaNumber,
          'requestername': data.requestername,
          'supportingdocs': updatedDocs,
          'shortdescription': data.shortdescription,
          'commercialscope': data.commercialscope,
          'sponsorname': {
            'email': data.sponsorname,
            'givenname': data['sponsorname-givenname'],
            'surname': data['sponsorname-surname']
          },
          'buysellagreement': data.buysellagreement,
          'agreementtype': data.agreementtype,
          'contractstartdate': data.contractstartdate,
          'lendleasecompany': data.lendleasecompany,
          'vendor': {                                                                                         // Populated if vendor exists from the vendor table
            'vendorname': data.vendorname === undefined ? '' : data.vendorname,
            'tradingas': data.vendorname ? data['vendorname-tradingas'] : '',
            'vendorid': data.vendorname ? data['vendorname-vendorid'] : ''
          },
          'newvendorname': data.newvendorname === undefined ? '' : data.newvendorname,                        // Populated if the vendor does not exist
          'newvendorcontactphone': data.newvendorphone === undefined ? '' : data.newvendorphone,
          'newvendorcontactemail': data.newvendoremail === undefined ? '' : data.newvendoremail,
          'newvendortradingname': data.newvendortradingname === undefined ? '' : data.newvendortradingname,
          'currency': data.currency,
          'estannualcontractvalue': data.estannualcontractvalue,
          'esttotalcontractvalue': data.esttotalcontractvalue,
          'projectcode': data.projectcode,
          'tasknumber': data.tasknumber,
          'expendituretype': data.expendituretype,
          'secondroundapprovals': [],
          'signedcontracts': [],
          'agreement': {
            'vendor': {
              'vendorname': '',
              'vendorid': ''      
            },
            'agreementstartdate': '',
            'agreementenddate': '',
            'agreementterm': {
              'term': '',
              'period' : ''
            },
            'currency': '',
            'totalagreementamount': '',
            'autorenewals': '',
            'noticeperiodforautorenewaltermination': '',
            'extensionagreement': '',
            'noticeperiodforextension': '',
            'executiondate': ''
          },
          'comments': []

        }

        return WriteDocument('tasks', newTaskId, commercialTask, true)
          .then(() => {

            // Reset Form and Close Pane
            reset();
            setFormData({
              'supportingDocs': []
            });
            setPaneStatus('onload');
            setPaneOpen({ 'commercial': false });

          })

          .catch((error) => {

            console.log(error)
            setPaneStatus('error');

          })

      });
  }

  //------------------------------------------------------
  //  useEffect
  //------------------------------------------------------

  // Set all the default values on the form
  useEffect(() => {

    if (task === undefined) return;

    setValue('applicationname', task?.applicationname);
    setValue('requestername', task?.createdby.email);
    setValue('region', task?.csmquestionnaire?.region);
    setValue('projectcode', task?.csmquestionnaire?.projectcode);
    setValue('tasknumber', task?.csmquestionnaire?.tasknumber);
    setValue('expendituretype', task?.csmquestionnaire?.expendituretype);

    // eslint-disable-next-line
  }, [task, paneOpen])

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

  // ==================================================
  //  Hide Side Pane
  // ==================================================

  if (!paneOpen?.commercial) return null;

  // ==================================================
  //  Show Side Pane  
  // ==================================================
  // ---------------------------------------------------
  //  Onload
  // ---------------------------------------------------

  if (paneStatus === 'onload') {

    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container' style={{ width: '65vw' }}>

          {/* Form */}
          <form className='w-full flex flex-col gap-2 overflow-hidden' onSubmit={handleSubmit(onSubmit)}>

            {/* Header */}
            <h4 className='mb-0'> Digital Commercial Request </h4>
            <p className='my-2 text-base'>This request will be sent to the Commercial team after submission.</p>

            {/* Form */}
            <div className='Task-Form' style={{ width: '54vw' }} >

              {/* ------------- REQUEST INFORMATION ------------- */}

              <h5 className='text-[18px] text-teal-600 mt-2 mb-1'>Request Information</h5>

              {/* =========================================================== */}
              {/*  1. Request Type                                            */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 1. Request Type <span className='text-[#C4314B]'>*</span></label>
                <select
                  className={errors.requesttype ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                  {...register('requesttype', {
                    required: 'This field is required'
                  })}
                  style={{ width: '300px' }}
                >
                  <option hidden value=''>-</option>
                  <option value='Establish New Agreement'> Establish New Agreement </option>
                  <option value='Vary/Extend Existing Agreement'> Vary/Extend Existing Agreement </option>
                  <option value='Terminate Existing Agreement'> Terminate Existing Agreement </option>
                  <option value='NDA'> NDA </option>

                </select>

                {errors.requesttype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.requesttype?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  2. Application Name                                        */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 2. Application Name <span className='text-[#C4314B]'>*</span></label>
                <input
                  className={errors.applicationname ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('applicationname', {
                    required: 'This field is required'
                  })}
                  placeholder='Enter application name'
                  type='text'
                ></input>

                {errors.applicationname && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.applicationname?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  3. Short Description                                       */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 3. Short Description <span className='text-[#C4314B]'>*</span></label>
                <textarea
                  className={errors.shortdescription ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                  {...register('shortdescription', {
                    required: 'This field is required'
                  })}
                  placeholder='Enter a description'
                ></textarea>

                {errors.shortdescription && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.shortdescription?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  4. Requester Name                                          */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 4. Requester Name <span className='text-[#C4314B]'>*</span></label>
                <div className='w-[400px]'>
                  <LookUpRHF
                    fieldId={'requestername'}
                    collectionId={'users'}
                    dataField={'emailaddress'}
                    placeholder={'Search user by email address'}
                    required={true}
                    register={register}
                    setValue={setValue}
                    errors={errors.itowner}
                    additionalQueries={['usertype', '==', 'lendleaseuser']}
                    clearErrors={clearErrors}
                  ></LookUpRHF>
                </div>
              </div>

              {/* =========================================================== */}
              {/*  5. Region                                                  */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 5. Region <span className='text-[#C4314B]'>*</span></label>
                <select
                  className={errors.region ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                  {...register('region', {
                    required: 'This field is required'
                  })}
                  style={{ width: '300px' }}
                  defaultValue={task?.csmquestionnaire?.region}
                >
                  <option hidden value=''>-</option>
                  <option value='AMER'> AMER </option>
                  <option value='ASIA'> ASIA </option>
                  <option value='AUST'> AUST </option>
                  <option value='EUR'> EUR </option>
                  <option value='Global'> Global </option>
                </select>

                {errors.region && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.region?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/* 6. Sponsor Name                                             */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 6. Sponsor Name <span className='text-[#C4314B]'>*</span></label>
                <div className='w-[400px]'>
                  <LookUpRHF
                    fieldId={'sponsorname'}
                    collectionId={'users'}
                    dataField={'emailaddress'}
                    placeholder={'Search user by email address'}
                    required={true}
                    register={register}
                    setValue={setValue}
                    dataFieldSecondary={'givenname'}
                    dataFieldTertiary={'surname'}
                    errors={errors.itowner}
                    additionalQueries={['usertype', '==', 'lendleaseuser']}
                    clearErrors={clearErrors}
                  ></LookUpRHF>
                </div>
              </div>

              {/* =========================================================== */}
              {/*  7. Supporting Documents                                    */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 7. Supporting Documents </label>

                {/* File Input */}
                <div className='Attach-File-Onload-Container'>

                  <input
                    className={formData?.supportingDocsError?.length === 0 ? ('Input-Field-Text') : ('Input-Field-Text-Error')}
                    style={{ padding: '0px' }}
                    type='file'
                    accept='.pdf,.doc,.txt,.xlsx,.docx,.csv,.png,.jpg'
                    onChange={(event) => {

                      const file = event.target.files[0];

                      if (file.size > 20000000) {
                        setFormData({
                          'supportingDocsError': 'File size too large. Please upload a file smaller than 20MB.'
                        });
                        return;
                      }

                      //Set File Id
                      const fileType = file.name?.split('.')[1];
                      const fileId = `${Date.now().toString()}${Math.floor(Math.random() * (99999 - 10000 + 1) + 10000)}.${fileType}`;

                      // Validate File Types
                      const allowedFileTypes = ['pdf', 'doc', 'txt', 'xlsx', 'docx', 'csv', 'png', 'jpg'];

                      if (allowedFileTypes.includes(fileType.toLowerCase()) === false) {
                        return setFormData({ 'supportingDocsError': `Please upload a file in the following format(s): ${allowedFileTypes.join(', ')}.` });
                      }

                      setFormData({
                        'supportingDocsError': '',
                        'supportingDocs': [
                          ...formData.supportingDocs,
                          {
                            'fileId': fileId,
                            'fileName': file.name,
                            'fileObject': file,
                            'fileType': fileType,
                          }
                        ]
                      });

                      // Clear File Input
                      event.target.value = '';

                    }}
                  ></input>
                </div>

                {/* Uploads */}
                <div className='w-full flex flex-col rounded-md border border-[#D8D8D8]' hidden={formData.supportingDocs.length > 0 ? false : true}>
                  {
                    formData?.supportingDocs?.map((file, index) => (
                      <div key={index} className='flex flex-row justify-between items-center border-b border-b-[#D8D8D8] last:border-0 px-3 py-1'>

                        {/* File Name */}
                        <div className='flex flex-row gap-2 items-center'>
                          <img src={FileIcon} alt='File'></img>
                          {file?.fileName}
                        </div>

                        {/* Delete Button */}
                        <div className='Cancel-Icon cursor-pointer mt-1' onClick={() => {

                          setFormData({
                            'supportingDocs': formData.supportingDocs.filter(item => item !== file)
                          });

                        }
                        }></div>

                      </div>
                    ))
                  }
                </div>

                {/* Error Message */}
                <label className='font-medium text-[#DE0000]' hidden={formData?.supportingDocsError.length === 0}>{formData?.supportingDocsError}</label>

              </div>

              {/* ------------- CONTRACT INFORMATION ------------- */}

              <h5 className='text-[18px] text-teal-600 mt-4 mb-2'>Contract Information</h5>

              {/* =========================================================== */}
              {/*  8. Detailed Request / Scope Description                    */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 8. Detailed Request / Scope Description <span className='text-[#C4314B]'>*</span></label>
                <textarea
                  className={errors.commercialscope ? ('Input-Field-TextArea-Error') : ('Input-Field-TextArea')}
                  {...register('commercialscope', {
                    required: 'This field is required'
                  })}
                  placeholder='Describe the scope of this request'
                ></textarea>

                {errors.commercialscope && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.commercialscope?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  9. Buy / Sell Agreement                                    */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 9. Is this a Buy or Sell Agreement? <span className='text-[#C4314B]'>*</span></label>
                <select
                  className={errors.buysellagreement ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                  {...register('buysellagreement', {
                    required: 'This field is required'
                  })}
                  style={{ width: '300px' }}
                >
                  <option hidden value=''>-</option>
                  <option value='Buy'> Buy </option>
                  <option value='Sell'> Sell </option>
                  <option value='N/A'> N/A </option>
                </select>

                {errors.buysellagreement && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.buysellagreement?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  10. Agreement Type                                         */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 10. Agreement Type <span className='text-[#C4314B]'>*</span></label>
                <select
                  className={errors.agreementtype ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                  {...register('agreementtype', {
                    required: 'This field is required'
                  })}
                  style={{ width: '300px' }}
                >
                  <option hidden value=''>-</option>
                  <option value='Consultancy Services Agreement (CSA)'> Consultancy Services Agreement (CSA) </option>
                  <option value='Hardware Order'> Hardware Order </option>
                  <option value='Letter of Intent (LoI)'> Letter of Intent (LoI) </option>
                  <option value='Master Services Agreement (MSA)'> Master Services Agreement (MSA) </option>
                  <option value='Non-Disclosure Agreement (NDA)'> Non-Disclosure Agreement (NDA) </option>
                  <option value='Novation/Assignment Agreement'> Novation/Assignment Agreement </option>
                  <option value='Service Order (SO)'> Service Order (SO) </option>
                  <option value='Software License'> Software License </option>
                  <option value='Subscription'> Subscription </option>
                  <option value='Supply Agreement'> Supply Agreement </option>
                  <option value='Variation'> Variation </option>
                </select>

                {errors.agreementtype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.agreementtype?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  11. Contract Start Date                                    */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 11. Contract Start Date <span className='text-[#C4314B]'>*</span></label>
                <input
                  className={errors.contractstartdate ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('contractstartdate', {
                    required: 'This field is required'
                  })}
                  type='date'
                ></input>

                {errors.contractstartdate && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.contractstartdate?.message}</p>}

              </div>

              {/* =========================================================== */}
              {/*  12. Lendlease Company                                      */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 12. Lendlease Company (Signing Entity) <span className='text-[#C4314B]'>*</span></label>
                <input
                  className={errors.lendleasecompany ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('lendleasecompany', {
                    required: 'This field is required'
                  })}
                  placeholder='Enter lendlease company'
                  type='text'
                ></input>

                {errors.lendleasecompany && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.lendleasecompany?.message}</p>}
              </div>

              {/* ------------- OTHER PARTY INFORMATION (VENDOR/CUSTOMER) ------------- */}

              <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Other Party Information (Vendor / Customer) </h5>

              {/* =========================================================== */}
              {/*  13. Vendor Name                                            */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 13. Vendor Name <span className='text-[#C4314B]'>*</span></label>
                <SelectVendorField
                  register={register}
                  setValue={setValue}
                  errors={errors}
                  clearErrors={clearErrors}
                ></SelectVendorField>

              </div>

              {/* ------------- FINANCIAL INFORMATION ------------- */}

              <h5 className='text-[18px] text-teal-600 mt-4 mb-2'> Financial Information </h5>

              {/* =========================================================== */}
              {/*  14. Currency                                               */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 14. Currency <span className='text-[#C4314B]'>*</span></label>
                <select
                  className={errors.currency ? ('Input-Field-Select-Error') : ('Input-Field-Select')}
                  {...register('currency', {
                    required: 'This field is required'
                  })}
                  style={{ width: '300px' }}
                >
                  <option hidden value=''>-</option>
                  <option value='AUD'> AUD </option>
                  <option value='SGD'> SGD </option>
                  <option value='GBP'> GBP </option>
                  <option value='USD'> USD </option>
                </select>

                {errors.currency && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.currency?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  15. Estimated Annual Contract Value (eTax)                 */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 15. Estimated Annual Contract Value (eTax) <span className='text-[#C4314B]'>*</span></label>
                <input
                  className={errors.estannualcontractvalue ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('estannualcontractvalue', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[0-9\s,]+$/,
                      message: "Only numbers, spaces, and commas are allowed"
                    }
                  })}
                  placeholder='Enter contract value'
                  type='text'
                ></input>

                {errors.estannualcontractvalue && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.estannualcontractvalue?.message}</p>}
              </div>

              {/* =========================================================== */}
              {/*  16. Estimated Total Contract Value (eTax)                  */}
              {/* =========================================================== */}

              <div className='Task-Form-Row'>
                <label className='font-medium'> 16. Estimated Total Contract Value (eTax) <span className='text-[#C4314B]'>*</span></label>
                <input
                  className={errors.esttotalcontractvalue ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('esttotalcontractvalue', {
                    required: 'This field is required',
                    pattern: {
                      value: /^[0-9\s,]+$/,
                      message: "Only numbers, spaces, and commas are allowed"
                    }
                  })}
                  placeholder='Enter contract value'
                  type='text'
                ></input>

                {errors.esttotalcontractvalue && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.esttotalcontractvalue?.message}</p>}
              </div>

              {/* ============================ */}
              {/*  17. Project Code *          */}
              {/* ============================ */}

              <div className='Task-Form-Row'>
                <label><b style={{ fontWeight: "500" }}> 17. Project Code *</b> </label>
                <input
                  className={errors.projectcode ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('projectcode', {
                    required: 'This field is required',
                    pattern: {
                      value: /^\d{6}$/,
                      message: 'This field must contain exactly 6 digits',
                    },
                  })}
                  placeholder='Enter a project code'
                  type='text'
                ></input>

                {errors.projectcode && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.projectcode?.message}</p>}
              </div>

              {/* ============================ */}
              {/*  18. Task Number *           */}
              {/* ============================ */}

              <div className='Task-Form-Row'>
                <label><b style={{ fontWeight: "500" }}> 18. Task Number * </b> </label>
                <input
                  className={errors.tasknumber ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('tasknumber', {
                    required: 'This field is required'
                  })}
                  placeholder='Enter a task number'
                  type='text'
                ></input>

                {errors.tasknumber && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.tasknumber?.message}</p>}
              </div>

              {/* ================================= */}
              {/*  19. Expenditure Type *           */}
              {/* ================================= */}

              <div className='Task-Form-Row'>
                <label><b style={{ fontWeight: "500" }}> 19. Expenditure Type * </b> </label>
                <input
                  className={errors.expendituretype ? ('Input-Field-Text-Error') : ('Input-Field-Text')}
                  style={{ width: '300px' }}
                  {...register('expendituretype', {
                    required: 'This field is required'
                  })}
                  placeholder='Enter expenditure type'
                  type='text'
                ></input>

                {errors.expendituretype && <p className='font-medium text-sm text-[#DE0000] my-2'>{errors.expendituretype?.message}</p>}
              </div>

            </div>

            {/* Buttons */}
            <div className='flex flex-row gap-2'>
              <button className='Primary-Button' type='submit' value='submit'> Create </button>
              <button className='Secondary-Button' onClick={() => {

                // Reset Form and Close Side Pane
                reset();
                setPaneOpen({ 'commercial': false });
                setFormData({
                  'supportingDocs': []
                });

              }}> Cancel </button>
            </div>

          </form>

        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Pending
  // ---------------------------------------------------

  else if (paneStatus === 'pending') {
    return (
      <div className='Pane-Background'>
        <dialog className='Pane-Container flex justify-center items-center text-center'>
          <img alt='loading-circle-icon' src={Loading}></img>
        </dialog>
      </div>
    )
  }

  // ---------------------------------------------------
  //  Error
  // ---------------------------------------------------

  else if (paneStatus === 'error') {
    return (
      <div className='Pane-Background'>

        {/* Pane Container */}
        <dialog className='Pane-Container'>
          <div className='flex flex-col gap-2 w-full justify-center items-center'>

            <img className='my-2 w-[100px]' src={Error} alt='Error'></img>
            <h4 className='mb-0'>Oops! Something went wrong.</h4>
            <p className='text-center leading-[1.7]'>
              An error occurred while we processed your request.
            </p>
            <button
              className='Primary-Button'
              onClick={() => {
                // Reset Form and Close Pane
                reset();
                setPaneOpen({ 'commercial': false });
                setFormData({
                  'supportingDocs': []
                });
              }}
            >
              Close
            </button>

          </div>
        </dialog>
      </div>

    )
  }

  //------------------------------------------------------

}