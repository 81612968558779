//================================================================
//  Component: Diligence & Governance Tab
//================================================================

//  Purpose: This contains all the other tasks related to this CSM task

//  Properties:
//    - task = {An object, contains the business request task details}
//    - setPageStatus = {A useState, used to determine the status of the page}
//    - setTotalTasks = {A useState, used to store the total number of tasks associated with this request}

//  Example:
//    <DiligenceAndGovernanceTab
//      task={task}
//      setPageStatus={setPageStatus}
//      setTotalTasks={setTotalTasks}
//    ></DiligenceAndGovernanceTab>    

// ==============================================================

// Libraries
import React, { useReducer, useState, useEffect } from 'react'

// Contexts

// Components
import CommercialTaskPane from './SidePanes/CommercialTaskPane';
import CorporateApprovalPane from './SidePanes/CorporateApprovalPane';
import Tooltip from '../../../../Components/Tooltip/Tooltip';
import CancelApprovalModal from '../Modals/CancelApprovalModal';
import ViewApproverPane from './SidePanes/ViewApproverPane';
import ProceedToNextStage from './ProceedToNextStage';

// Functions
import QueryListener from '../../../../Library/QueryListener';
import ConvertDate from '../../../../Library/ConvertDate';

// Images
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';
import CancelDisabled from '../../../../Components/Images/Icon_CancelDisabled_Grey.svg'

// CSS
import '../../Task.css'

export default function DiligenceAndGovernanceTab({
    task,
    setPageStatus,
    setTotalTasks
}) {

    //------------------------------------------------------
    //  useState
    //------------------------------------------------------

    // Used to store all the tasks related to this CSM task
    const [allTasks, setAllTasks] = useState([]);

    // Used to toggle the visibility of the cancel approval modal and save the current approval
    const [cancelModalApprover, setCancelModalApprover] = useState(undefined);

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded and if the create pane is closed
    const [taskSectionCollapsed, setTaskSectionCollapsed] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'idcgArchitecture': true,
            'corporateApproval': true,
            'cyber': true,
            'commercial': true,
            'procurement': true,
            'opsPlanningAndExecution': true,
        }
    );

    // Used to store whether the Create task pane is open
    const [paneOpen, setPaneOpen] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'idcgArchitecture': false,
            'corporateApproval': false,
            'cyber': false,
            'commercial': false,
            'procurement': false,
            'opsPlanningAndExecution': false,
        }
    );

    // Used to store whether the View task pane is open
    const [viewPaneOpen, setViewPaneOpen] = useReducer(
        (state, newState) => ({ ...state, ...newState }),
        {
            'commercial': undefined,
            'corporateApproval': undefined,
        }
    );

    //------------------------------------------------------
    //  Functions
    //------------------------------------------------------

    // Check if section has any tasks
    function hasTask(taskType) {

        //Returns True or False
        return allTasks.filter((task) => task?.tasktype === taskType)?.length > 0;

    }

    //------------------------------------------------------
    //  useEffect
    //------------------------------------------------------

    // Get all the related tasks
    useEffect(() => {

        if (task === undefined) return;

        function onLoadChange(document) {

            setTotalTasks(document);
            setAllTasks(document);

        }

        function onError(error) {
            console.log(error);
            setPageStatus('error-fatal');

        }

        const unsubscribe = QueryListener('tasks', [
            ['requestid', '==', task?.requestid],
            ['tasktype', 'not-in', ['Business Request', 'Line Manager Approval', 'Finance Manager Approval', 'Instant Vendor Risk Report']],        
        ], onLoadChange, onLoadChange, onError);

        return () => {
            unsubscribe();
        };

        // eslint-disable-next-line
    }, [task]);

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 p-0'>

            {/* ===================================================== */}
            {/*   Commercial                                          */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[5px] items-center justify-between cursor-pointer' >

                    <div className='flex flex-row items-center gap-2' onClick={() => setTaskSectionCollapsed({ 'commercial': !taskSectionCollapsed.commercial })}>
                        <img
                            className='cursor-pointer'
                            src={taskSectionCollapsed?.commercial ? Collapse : Expand}
                            alt={'>'}
                        ></img>

                        <h4 className='text-[20px] m-0'> Commercial </h4>
                    </div>

                    <div className='flex flex-row gap-2 items-center'>
                        <div className='flex w-[30px] h-[30px] items-center justify-center px-2 py-0 rounded-md font-medium text-[#00A7A4] border-1 border-[#00A7A4]' hidden={!hasTask('Commercial Review')}>
                            {allTasks.filter((task) => task?.tasktype === 'Commercial Review')?.length}
                        </div>
                        <button
                            className={hasTask('Commercial Review') ? 'Secondary-Button' : 'Primary-Button'}
                            onClick={() => setPaneOpen({ 'commercial': true })}
                            disabled={task?.status === 'Completed' || task?.stage === 'Publishing'}
                        >
                            + Create
                        </button>
                    </div>

                </div>
                <hr className='m-0' hidden={taskSectionCollapsed?.commercial}></hr>

                {/* ------------- COMMERCIAL ------------- */}

                <div className='p-[3%] flex flex-row justify-between' hidden={taskSectionCollapsed?.commercial}>

                    <div className='w-full border border-[#D8D8D8] rounded px-[1%]'>
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr>
                                    <th className='py-[15px] px-[5px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>Created</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Task Name</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Assigned To</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                                    <th className='py-[15px] px-[10px] font-medium'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allTasks.filter((task) => task?.tasktype === 'Commercial Review')?.length === 0 || allTasks.filter((task) => task?.tasktype === 'Commercial Review') === undefined ?
                                        (
                                            <tr>
                                                <td className='border-t border-t-[#D8D8D8]' colSpan={7}>
                                                    <div className='text-center p-[2%]'>
                                                        Click '<strong>+</strong> Create' to add a task
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            allTasks.filter((item) => item?.tasktype === 'Commercial Review')?.map((item, index) => (
                                                <tr key={index} className='hover:bg-[#F0F7F7]'>

                                                    <td className='py-[10px] px-[5px] border-t border-t-[#D8D8D8]'></td>

                                                    {/* Created */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {ConvertDate(item?.createddate)}
                                                    </td>

                                                    {/* PoA Number */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {item?.taskname}
                                                    </td>

                                                    {/* Modified Date */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {ConvertDate(item?.lastmodifieddate)}
                                                    </td>

                                                    {/* Assigned To */}
                                                    <td className='py-[10px] pl-[10px] pr-[0px] border-t border-t-[#D8D8D8]'>
                                                        {
                                                            item?.assignedto?.email === 'Unassigned' ?
                                                                <label>Unassigned</label>
                                                                :
                                                                <div className='flex flex-col'>
                                                                    <label className='capitalize'>{item?.assignedto?.givenname} {item?.assignedto?.surname} </label>
                                                                    <label className='text-[14px] text-[#616161]'>{item?.assignedto?.email}</label>
                                                                </div>
                                                        }

                                                    </td>

                                                    {/* Status */}
                                                    <td className='pt-[15px] pb-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        <div>
                                                            {
                                                                [
                                                                    { 'name': 'Not Started', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Not Started' },
                                                                    { 'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress' },
                                                                    { 'name': 'Completed', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed' },
                                                                    { 'name': 'Cancelled', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Cancelled' },
                                                                    { 'name': 'Rejected', 'background': '#45AAF2', 'color': 'white', 'bordercolor': '#45AAF2', 'status': 'Rejected' },

                                                                ].filter((style) => style?.status === item?.status).map((object, index) => (
                                                                    <label key={index} className='Status-Label' style={{ backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}` }} >
                                                                        {object.name}
                                                                    </label>
                                                                ))
                                                            }
                                                        </div>
                                                    </td>

                                                    {/* Cancel */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'></td>

                                                </tr>
                                            ))
                                        )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*   Corporate Approval                                  */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>

                <div className='flex flex-row gap-3 px-[2%] py-[5px] items-center justify-between cursor-pointer'    >

                    <div
                        className='flex flex-row items-center gap-2'
                        onClick={() => setTaskSectionCollapsed({ 'corporateApproval': !taskSectionCollapsed.corporateApproval })}
                    >
                        <img
                            className='cursor-pointer'
                            src={taskSectionCollapsed?.corporateApproval ? Collapse : Expand}
                            alt={'>'}
                        ></img>

                        <h4 className='text-[20px] m-0'> Corporate Approval </h4>
                    </div>

                    <div className='flex flex-row gap-2 items-center'>
                        <div className='flex w-[30px] h-[30px] items-center justify-center px-2 py-0 rounded-md font-medium text-[#00A7A4] border-1 border-[#00A7A4]' hidden={!hasTask('Corporate Approval')}>
                            {allTasks.filter((task) => task?.tasktype === 'Corporate Approval' && task?.status === 'In Progress')?.length}
                        </div>
                        <button
                            className={hasTask('Corporate Approval') ? 'Secondary-Button' : 'Primary-Button'}
                            onClick={() => setPaneOpen({ 'corporateApproval': true })}
                            disabled={task?.status === 'Completed' || task?.stage === 'Publishing'}
                        >
                            + Create
                        </button>
                    </div>

                </div>
                <hr className='m-0' hidden={taskSectionCollapsed?.corporateApproval}></hr>

                {/* ------------- CORPORATE APPROVAL ------------- */}

                <div className='p-[3%] flex flex-row justify-between' hidden={taskSectionCollapsed?.corporateApproval}>

                    <div className='w-full border border-[#D8D8D8] rounded px-[1%]'>
                        <table className='w-full max-h-96 overflow-y-scroll'>
                            <thead>
                                <tr>
                                    <th className='py-[15px] px-[5px] font-medium'></th>
                                    <th className='py-[15px] px-[10px] font-medium'>Approver</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Subject</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Modified</th>
                                    <th className='py-[15px] px-[10px] font-medium'>Status</th>
                                    <th className='py-[15px] px-[10px] font-medium'></th>
                                </tr>
                            </thead>
                            <tbody>
                                {
                                    allTasks.filter((task) => task?.tasktype === 'Corporate Approval')?.length === 0 || allTasks.filter((task) => task?.tasktype === 'Corporate Approval') === undefined ?
                                        (
                                            <tr>
                                                <td className='border-t border-t-[#D8D8D8]' colSpan={6}>
                                                    <div className='text-center p-[2%]'>
                                                        Click '<strong>+</strong> Create' to add a task
                                                    </div>
                                                </td>
                                            </tr>
                                        )
                                        :
                                        (
                                            allTasks.filter((task) => task?.tasktype === 'Corporate Approval')?.map((item, index) => (
                                                <tr key={index} className='hover:bg-[#F0F7F7]'>

                                                    <td className='py-[10px] px-[5px] border-t border-t-[#D8D8D8]'></td>

                                                    {/* Approver */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8] cursor-pointer' onClick={() => {
                                                        setViewPaneOpen({ 'corporateApproval': item })
                                                    }}>
                                                        {
                                                            item?.assignedto?.email === 'Unassigned' ?
                                                                <label>Unassigned</label>
                                                                :
                                                                <div className='flex flex-col'>
                                                                    <label className='capitalize hover:underline cursor-pointer hover:font-medium'>{item?.assignedto?.givenname} {item?.assignedto?.surname} </label>
                                                                    <label className='text-[14px] text-[#616161] cursor-pointer'>{item?.assignedto?.email}</label>
                                                                </div>
                                                        }
                                                    </td>

                                                    {/* PoA Number */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {item?.taskname}
                                                    </td>

                                                    {/* Modified Date */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {ConvertDate(item?.lastmodifieddate)}
                                                    </td>

                                                    {/* Status */}
                                                    <td className='pt-[15px] pb-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        <div>
                                                            {
                                                                [
                                                                    { 'name': 'Not Started', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'Not Started' },
                                                                    { 'name': 'In Progress', 'background': '#FBF6D9', 'color': '#DC9A03', 'bordercolor': '#DC9A03', 'status': 'In Progress' },
                                                                    { 'name': 'Completed', 'background': '#E7F2DA', 'color': '#237B4B', 'bordercolor': '#237B4B', 'status': 'Completed' },
                                                                    { 'name': 'Cancelled', 'background': '#FCF4F6', 'color': '#C4314B', 'bordercolor': '#C4314B', 'status': 'Cancelled' },
                                                                    { 'name': 'Rejected', 'background': '#45AAF2', 'color': 'white', 'bordercolor': '#45AAF2', 'status': 'Rejected' },

                                                                ].filter((style) => style?.status === item?.status).map((object, index) => (
                                                                    <label key={index} className='Status-Label' style={{ backgroundColor: `${object.background}`, color: `${object.color}`, border: `1px solid ${object.bordercolor}` }} >
                                                                        {object.name}
                                                                    </label>
                                                                ))
                                                            }
                                                        </div>
                                                    </td>

                                                    {/* Cancel */}
                                                    <td className='py-[10px] px-[10px] border-t border-t-[#D8D8D8]'>
                                                        {
                                                            item?.status !== 'In Progress' ?
                                                                <img src={CancelDisabled} alt='Cancel' className='cursor-not-allowed min-w-[24px]'></img>
                                                                :
                                                                <Tooltip
                                                                    message='Cancel Approval'
                                                                    children={
                                                                        <div
                                                                            className='Cancel-Approval'
                                                                            onClick={() => setCancelModalApprover(item)}
                                                                        />
                                                                    }
                                                                ></Tooltip>
                                                        }
                                                    </td>

                                                </tr>
                                            ))
                                        )
                                }
                            </tbody>
                        </table>
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Proceed to Next Stage Button                         */}
            {/* ===================================================== */}

            <ProceedToNextStage
                task={task}
            ></ProceedToNextStage>

            {/* ===================================================== */}
            {/*   Side Panes                                          */}
            {/* ===================================================== */}

            <CommercialTaskPane
                paneOpen={paneOpen}
                setPaneOpen={setPaneOpen}
                task={task}
            ></CommercialTaskPane>

            <CorporateApprovalPane
                paneOpen={paneOpen}
                setPaneOpen={setPaneOpen}
                task={task}
            ></CorporateApprovalPane>

            <ViewApproverPane
                viewPaneApprover={viewPaneOpen}
                setViewPaneApprover={setViewPaneOpen}
            ></ViewApproverPane>

            {/* ===================================================== */}
            {/*  Modals                                               */}
            {/* ===================================================== */}

            <CancelApprovalModal
                cancelModalApprover={cancelModalApprover}
                setCancelModalApprover={setCancelModalApprover}
            ></CancelApprovalModal>

        </div>
    )
}
