//================================================================
//  Component: Triage Tab
//================================================================

//  Purpose: This contains the triage information for a business request task

//  Properties:
//    - task = {An object, contains the business request task details}

//  Example:
//    <TriageTab
//      task={task}
//    ></TriageTab>    

// ==============================================================

// Libraries
import React, { useReducer } from 'react'

// Contexts

// Components
import AssignTask from '../../../../Components/AssignTask/AssignTask';

//Functions
import ConvertDate from '../../../../Library/ConvertDate';

// Images
import Expand from '../../../../Components/Images/Icon_Collapse_Teal.svg';
import Collapse from '../../../../Components/Images/Icon_Expand_Teal.svg';

// CSS
import '../../Task.css'

export default function TriageTab({
    task
}) {

    //------------------------------------------------------
    //  useReducer
    //------------------------------------------------------

    // Used to store whether a section is expanded
    const [taskSection, setTaskSection] = useReducer(
        (state, newState) => ({...state, ...newState}),
        {
            'businessRequestCollapsed': true,
        }
    );

    //------------------------------------------------------
    //  HTML
    //------------------------------------------------------

    return (
        <div className='flex flex-col gap-2 p-0'>

            {/* ===================================================== */}
            {/*  Assigned To                                          */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>
                <h4 className='text-[20px] px-[3%] py-[18px] m-0'>Assigned To</h4>
                <hr className='m-0'></hr>

                <div className='p-[3%]'>

                    <div className='FormComponent-Row'>
                        <label className='font-medium mt-0'> Assignee </label>
                        {
                            task !== undefined &&
                            <AssignTask
                                query={['roles.csmUser', '==', true]}
                                currentRequest={task}
                                styleInput={{
                                    width: '300px'
                                }}
                                disabled={(task?.status === 'Completed' || task?.status === 'Rejected' || task?.status === 'Cancelled') ? true : false}
                            ></AssignTask>
                        }
                    </div>
                    
                    {/* =========================================================== */}
                    {/*  Date Assigned                                              */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Date Assigned </label>
                        <input
                            className='Input-Field-Text'
                            style={{width: '300px'}}
                            type='text'
                            defaultValue={ConvertDate(task?.assigneddate)}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  Request Type                                               */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Type of Request </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.requesttype}
                            disabled
                        ></input>
                        
                    </div>

                </div>

            </div>

            {/* ===================================================== */}
            {/*  Business Request                                     */}
            {/* ===================================================== */}

            <div className='Task-Section-Container'>
                
                <div className='flex flex-row gap-3 px-[2%] py-[18px] cursor-pointer' onClick={() => setTaskSection({ 'businessRequestCollapsed': !taskSection.businessRequestCollapsed })}>
                    <img
                        className='cursor-pointer'
                        src={taskSection?.businessRequestCollapsed ? Collapse : Expand}
                        alt={'>'}
                    ></img>
                    <h4 className='text-[20px] m-0'> Business Request </h4>
                </div>
                <hr className='m-0' hidden={taskSection?.businessRequestCollapsed}></hr>

                {/* ------------- BUSINESS REQUEST ------------- */}

                <div className='p-[3%]' hidden={taskSection?.businessRequestCollapsed}>

                    {/* =========================================================== */}
                    {/*  1. What is the name of this application?                   */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 1. What is the name of this application? </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.applicationname}
                            disabled
                        ></input>
                    </div>

                    {/* ================================================================ */}
                    {/*  2. Please provide a short description of your request. *        */}
                    {/* ================================================================ */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 2. Please provide a short description of your request. * </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            defaultValue={task?.taskdescription}
                            disabled
                        ></textarea>
                    </div>

                    {/* ============================================================================ */}
                    {/*  3. Please list business processes that this application is used for. *      */}
                    {/* ============================================================================ */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 3. Please list business processes that this application is used for. * </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            defaultValue={task?.shortdescription}
                            disabled
                        ></textarea>
                    </div>
    
                    {/* =========================================================== */}
                    {/*  4. Who is the business owner of this application?          */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 4. Who is the business owner of this application? </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.businessowner}
                            disabled
                        ></input>
                    </div>

                    {/* ========================================================================= */}
                    {/*  5. Who is the project lead / business lead supporting this application?  */}
                    {/* ========================================================================= */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 5. Who is the project lead / business lead supporting this application? </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.businesslead}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  6. Who is your line manager?                               */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 6. Who is your line manager? </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.linemanager}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  7. Who is the finance manager for this request?            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 7. Who is the finance manager for this request? </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.financemanager}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  8. Project Code                                            */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 8. Project Code </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px'}}
                            type='text'
                            defaultValue={task?.projectcode}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  9. Task Number                                             */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 9. Task Number </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px'}}
                            type='text'
                            defaultValue={task?.tasknumber}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  10. Expenditure Type                                       */}
                    {/* =========================================================== */}                                
                    
                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 10. Expenditure Type </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px'}}
                            type='text'
                            defaultValue={task?.expendituretype}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  11. Who is budgeting for this?                             */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 11. Who is budgeting for this? </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.budgeting}</option>
                        </select>
                    </div>

                    {/* ==================================================================================== */}
                    {/*  12. Who will be the primary beneficiary of this business improvement solution?      */}
                    {/* ==================================================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 12. Who will be the primary beneficiary of this business improvement solution? </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.primarybeneficiary}</option>
                        </select>
                    </div>

                    {/* ======================================================================================= */}
                    {/*  13. Who will be the secondary beneficiary(s) for this business improvement solution?   */}
                    {/* ======================================================================================= */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 13. Who will be the secondary beneficiary(s) for this business improvement solution? </label>
                        <select className='Input-Field-MultiSelect' defaultValue={task?.secondarybeneficiary} multiple disabled>
                            <option hidden defaultValue=''>-</option>
                            <option defaultValue='Corporate Affairs and Marketing'> Corporate Affairs and Marketing </option>
                            <option defaultValue='EHS'> EHS </option>
                            <option defaultValue='Finance'> Finance </option>
                            <option defaultValue='Group Investor Relations'> Group Investor Relations </option>
                            <option defaultValue='IT'> IT </option>
                            <option defaultValue='Legal'> Legal </option>
                            <option defaultValue='People & Culture'> People & Culture </option>
                            <option defaultValue='Risk and Insurance'> Risk and Insurance </option>
                            <option defaultValue='Supply Chain'> Supply Chain </option>
                            <option defaultValue='Sustainability'> Sustainability </option>
                            <option defaultValue='Tax'> Tax </option>
                            <option defaultValue='Treasury'> Treasury </option>
                        </select>
                    </div>

                    {/* ===================================================================== */}
                    {/*  14. Commercial Entity to sign application agreement (if known)       */}
                    {/* ===================================================================== */}                                
                    
                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 14. Commercial Entity to sign application agreement (if known) </label>
                        <input
                            className='Input-Field-Text'
                            type='text'
                            defaultValue={task?.commercialentity}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  15. Approximate number of users                            */}
                    {/* =========================================================== */}                                
                    
                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 15. Approximate number of users </label>
                        <input
                            className='Input-Field-Text'
                            style={{ width: '200px'}}
                            type='number'
                            defaultValue={task?.totalusers}
                            disabled
                        ></input>
                    </div>

                    {/* ======================================= */}
                    {/*  16. What type of request is this?      */}
                    {/* ======================================= */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 16. What type of request is this? </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.requesttype}</option>
                        </select>
                    </div>

                    {/* =================================================================== */}
                    {/*  17. Will this application improve one or more of the following?    */}
                    {/* =================================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 17. Will this application improve one or more of the following?  </label>
                        <select className='Input-Field-MultiSelect' defaultValue={task?.improvements} multiple disabled>
                            <option hidden defaultValue=''>-</option>
                            <option defaultValue='Business efficiency'> Business efficiency </option>
                            <option defaultValue='Customer / Client Satisfaction'> Customer / Client Satisfaction </option>
                            <option defaultValue='Enhancement of existing business critical systems'> Enhancement of existing business critical systems </option>
                            <option defaultValue='ESG requirements or credentials'> ESG requirements or credentials </option>
                            <option defaultValue='Investment in our people'> Investment in our people </option>
                            <option defaultValue='Overcome risks associated with shadow IT'> Overcome risks associated with shadow IT </option>
                            <option defaultValue='Regulatory compliance'> Regulatory compliance </option>
                            <option defaultValue='Risk mitigation'> Risk mitigation </option>
                        </select>
                    </div>

                    {/* ========================================================================================= */}
                    {/*  18. Provide a summary of how this initiative will improve the items chosen above.        */}
                    {/* ========================================================================================= */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 18. Provide a summary of how this initiative will improve the items chosen above. </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            defaultValue={task?.summary}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  19. Does this initiative drive revenue growth?             */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 19. Does this initiative drive revenue growth? </label>
                        <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                            <label className='Radio-Buttons-Container'>
                                Yes
                                <input type='radio' name='revenuegrowth' value='yes' checked={task?.revenuegrowth?.toLowerCase() === 'yes'} disabled></input>
                                <span className='Radio-Checkmark'></span>
                            </label>

                            <label className='Radio-Buttons-Container'>
                                No
                                <input type='radio' name='revenuegrowth' value='no' checked={task?.revenuegrowth?.toLowerCase() === 'no'} disabled></input>
                                <span className='Radio-Checkmark'></span>
                            </label>
                        </div>
                    </div>

                    {/* =========================================================== */}
                    {/*  19a. If yes, when would revenue growth commence?*          */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row' hidden={task?.revenuegrowth?.toLowerCase() === 'no'}>
                        <label className='font-medium'> 19. Does this initiative drive revenue growth? </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.arevenuegrowthcommence}</option>
                        </select>
                    </div>

                    {/* =========================================================== */}
                    {/*  20. Does this product accelerate funds growth?             */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 20. Does this product accelerate funds growth? </label>
                        <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                            <label className='Radio-Buttons-Container'>
                                Yes
                                <input type='radio' name='fundsgrowth' value='yes' checked={task?.fundsgrowth?.toLowerCase() === 'yes'} disabled></input>
                                <span className='Radio-Checkmark'></span>
                            </label>

                            <label className='Radio-Buttons-Container'>
                                No
                                <input type='radio' name='fundsgrowth' value='no' checked={task?.fundsgrowth?.toLowerCase() === 'no'} disabled></input>
                                <span className='Radio-Checkmark'></span>
                            </label>
                        </div>
                    </div>

                    {/* =========================================================== */}
                    {/*  20a. If yes, when would funds growth commence? *           */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row' hidden={task?.fundsgrowth?.toLowerCase() === 'no'}>
                        <label className='font-medium'> 20a. If yes, when would funds growth commence? </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.afundsgrowthcommence}</option>
                        </select>
                    </div>

                    {/* ========================================================== */}
                    {/*  21. What LoA Type is this initiative / application?       */}
                    {/* ========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 21. What LoA Type is this initiative / application?  </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.loatype}</option>
                        </select>
                    </div>

                    {/* ==================================================================== */}
                    {/*  22. What is the anticipated complete cost of this initiative?       */}
                    {/* ==================================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> 22. What is the anticipated complete cost of this initiative? </label>
                        <select className='Input-Field-Select' disabled>
                            <option>{task?.anticipatedcost}</option>
                        </select>
                    </div>

                    {/* =================================================== */}
                    {/*  Comments                                           */}
                    {/* =================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Comments </label>
                        <textarea
                            className='Input-Field-TextArea'
                            type='text'
                            defaultValue={task?.comments}
                            disabled
                        ></textarea>
                    </div>

                    {/* =========================================================== */}
                    {/*  Created By                                                 */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Created By </label>
                        <input
                            className='Input-Field-Text'
                            style={{width: '300px'}}
                            type='text'
                            defaultValue={task?.createdby?.email}
                            disabled
                        ></input>
                    </div>

                    {/* =========================================================== */}
                    {/*  Created Date                                               */}
                    {/* =========================================================== */}

                    <div className='FormComponent-Row'>
                        <label className='font-medium'> Created Date </label>
                        <input
                            className='Input-Field-Text'
                            style={{width: '300px'}}
                            type='text'
                            defaultValue={ConvertDate(task?.createddate)}
                            disabled
                        ></input>
                    </div>

                </div>

            </div>

        </div>
    );
}
