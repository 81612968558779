//================================================================
//  Page: My Tasks
//================================================================

//  Purpose: This page shows all business requests assigned to the current user

//================================================================


// Libraries
import React, { useReducer } from 'react';

// Contexts

// Components
import PageComponent from '../../Components/PageComponent/PageComponent';
import TabView from '../../Components/TabView/TabView';

// Tabs
import InProgress from './Tabs/InProgress';
import Completed from './Tabs/Completed';
import All from './Tabs/All';

// Functions

// Images

// CSS
import './MyTasks.css';

 
export default function MyTasks() {

  //------------------------------------------------------
  //  useReducer
  //------------------------------------------------------

    // Used to store the total requests per stage/view
    const [requests, setRequests] = useReducer(
      (state, newState) => ({...state, ...newState}),
      {
        'inProgressRequests': [],
        'inProgressRequestsFiltered': [],
        'completedRequests': [],
        'completedRequestsFiltered': [],
        'renewalRequests': [],
        'renewalRequestsFiltered': [],
        'allRequests': [],
        'allRequestsFiltered': [],
      }
    );

  //------------------------------------------------------
  //  HTML
  //------------------------------------------------------

    return (
      <PageComponent
        header={
          <div className='Page-Header-Container'>
            <p className='font-medium text-[20px] mb-0'> My Tasks </p>
          </div>
        }
        body={
          <TabView
            defaultView={1}

            //------------------------------------------------------
            //  In Progress
            //------------------------------------------------------

            oneTitle={
              <div className='flex flex-row gap-2'>
                In Progress
                <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.inProgressRequests?.length === 0}>
                  {requests?.inProgressRequests?.length}
                </div>
              </div>
            }
            oneRoute = {'/mytasks?view=inprogress'}
            oneContent={
              <InProgress
                requests={requests}
                setRequests={setRequests}
              ></InProgress>
            }
            //------------------------------------------------------
            //  Completed
            //------------------------------------------------------

            twoTitle={'Completed'}
            twoRoute = {'/mytasks?view=completed'}
            twoContent={
              <Completed
                requests={requests}
                setRequests={setRequests}
              ></Completed>
            }

            //------------------------------------------------------
            // Renewals
            //------------------------------------------------------

            threeTitle={
              <div className='flex flex-row gap-2'>
                Renewals
                <div className='flex items-center justify-center h-[22px] w-[22px] rounded-full bg-[#006866] text-white text-[12px] font-bold' hidden={requests?.renewalRequests?.length === 0}>
                  {requests?.renewalRequests?.length}
                </div>
              </div>
            }
            threeRoute = {'/mytasks?view=renewals'}
            threeContent={
              <div className='flex flex-col px-[15px] gap-[20px]'>
                Renewals
              </div>
            }

            //------------------------------------------------------
            //  Decommissioned
            //------------------------------------------------------

            fourTitle={'Decommissioned'}
            fourRoute = {'/mytasks?view=decommissioned'}
            fourContent={
              <div className='flex flex-col px-[15px] gap-[20px]'>
                Decommissioned
              </div>
            }

            //------------------------------------------------------
            //  All
            //------------------------------------------------------

            fiveTitle={'All'}
            fiveRoute = {'/mytasks?view=all'}
            fiveContent={
              <All
                requests={requests}
                setRequests={setRequests}
              ></All>
            }

          ></TabView>    
        }
      ></PageComponent> 
    )
}
