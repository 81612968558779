//================================================================
//  Component: Live Field
//================================================================

//  Purpose: onBlur automatically save content to Firebase, handles errors and animations

//  Properties:
//    - type = {'text', 'multiselect', 'choice', 'multiline', 'timestamp' (READ ONLY), 'date-string', 'user-search' (Saves an object), 'lookup' (provide keys), & 'number' (SAVED as string for formatting)
//    - collectionid = {String, Firestore collection id}
//    - documentid = {String, Firestore document id}
//    - fieldpath = {String, Firestore field path}
//    - label = {HTML, Label Tag}
//    - defaultValue = {string, onload value for the input}
//    - options = <OPTIONAL> {Array, options for a selectbox dropdown}
//    - disabled = <OPTIONAL> {boolean, enable or disable the input}
//    - styleInput = <OPTIONAL> {object, CSS style object}
//    - placeholder = <OPTIONAL> {string, contains the placeholder text for the input field}
//    - dataFieldCollectionid = <REQUIRED 'lookup'> {string, collectionid to query}
//    - dataFieldPrimary = <REQUIRED 'lookup'> {string, field key which is saved to the Firestore document and SHOW in the drop down}
//    - dataFieldSecondary = <Optional 'lookup'> {string, field key which is saved to the Firestore document and SHOW in the drop down}
//    - dataFieldTertiary = <Optional 'lookup'> {string, field key which is saved to the Firestore document and IS NOT shown in the dropdown. Good for a documentid, etc}
//    - createNewHTML = <Optional 'lookup'> {HTML, generic html attached to the bottom of the search results}

//  Examples:

//    <LiveField
//      type={'text'}
//      collectionid={'agreements'}
//      documentid={agreementid}
//      fieldpath={'agreementstatus'}
//      label={<label className='font-medium'>1. Process Name <span className='text-[#C4314B]'>*</span> </label>}
//      defaultValue={'Active'}
//      options={arrayOfOptions}
//      disabled={false}
//      styleInput={{padding: '5px'}}
//      placeholder={'Select Status'}
//    ></LiveField>    


//    <LiveField
//      type={'lookup'}
//      collectionid={'agreements'}
//      documentid={agreementDoc?.agreementid}
//      fieldpath={'vendor'}
//      label={<label className='font-medium'> Vendor Name </label>}
//      defaultValue={agreementDoc?.vendor?.vendorname}
//      placeholder={'Select a vendor'}
//      dataFieldCollectionid={'vendors'}
//      dataFieldPrimary={'vendorname'}
//      dataFieldSecondary={'tradingas'}
//      dataFieldTertiary={'vendorid'}
//      createNewHTML={<span className='font-normal text-[#A0A0A0]'>Can't find what you're looking for? Click <a href='http://console.store.lendlease.com/inventory?view=vendors' target='_blank' rel='noopener noreferrer'>here</a>.</span>}
//    ></LiveField> 

//================================================================


//Libraries
import React, { useContext, useEffect, useState } from 'react';

//Contexts
import { GetUser } from '../../Library/GlobalContexts';

//Components
import UserSearchLiveFields from './Components/UserSearchLiveFields';
import LookUpLiveFields from './Components/LookUpLiveFields';

//Functions
import WriteDocument from '../../Library/WriteDocument';
import ConvertDate from '../../Library/ConvertDate';

//Images
import successIcon from '../Images/Icon_Save_Teal.svg';
import pendingIcon from '../Images/Icon_LoadingFile_Grey.gif';
import errorIcon from '../Images/Icon_Error_Red.svg';

//CSS
import './LiveField.css';


export default function LiveField({
  type,
  collectionid,
  documentid,
  fieldpath,
  label,
  defaultValue,
  options,
  disabled,
  styleInput,
  placeholder,

  // 'lookup' type props
  dataFieldCollectionid,
  dataFieldPrimary,
  dataFieldSecondary,
  dataFieldTertiary,
  createNewHTML,
}) {

  //------------------------------------------------------
  //  useContexts
  //------------------------------------------------------

  const getUser = useContext(GetUser);

  //------------------------------------------------------
  //  useStates
  //------------------------------------------------------

  // Handles the field state --> onload, pending, success, error
  const [fieldStatus, setFieldStatus] = useState('onload');

  // Holds the current field value
  const [fieldValue, setFieldValue] = useState();

  // Holds the current error message
  const [fieldError, setFieldError] = useState('');

  //------------------------------------------------------
  //  Functions
  //------------------------------------------------------

  function handleBlur(value, skipFieldValue) {

    // Skip writing NO changes to Firestore
    if ((skipFieldValue === undefined || skipFieldValue === false) && defaultValue === fieldValue) return;

    setFieldStatus('pending');

    //------------------------------------------------------
    //  Number Field Validation
    //------------------------------------------------------

    if (type === 'number') {

      value = value.replaceAll(',', '');

      // Format into something pretty
      value = new Intl.NumberFormat('en-GB').format(value);

      setFieldValue(value);

    }

    // Prepare firestore document
    let document = {
      'lastmodifieddate': new Date(),
      'lastmodifiedby': {
        'email': getUser?.emailaddress,
        'givenname': getUser?.givenname,
        'surname': getUser?.surname
    }
    };

    //------------------------------------------------------
    //  Handle nested fieldpaths & add value to Firestore document
    //------------------------------------------------------

    if (fieldpath.split('.')?.length > 0) {

      const keys = fieldpath.split('.');
      const results = {};

      if (keys.length === 1) {

        results[keys[0]] = value;

      }
      if (keys.length === 2) {

        results[keys[0]] = {
          [keys[1]]: value
        };

      }
      if (keys.length === 3) {

        results[keys[0]] = {
          [keys[1]]: {
            [keys[2]]: value
          }
        };

      }
      if (keys.length === 4) {

        results[keys[0]] = {
          [keys[1]]: {
            [keys[2]]: {
              [keys[3]]: value
            }
          }
        };

      }

      if (keys.length === 5) return setFieldError('Unable to support this many nested fields!!!!');

      // Merge the objects
      Object.assign(document, results);

    }

    //------------------------------------------------------
    //  No nested fields --> Add value to firestore document
    //------------------------------------------------------

    if (fieldpath.split('.')?.length === 0) {

      document[fieldpath] = value;

    }

    //------------------------------------------------------
    //  Proceed with updating document in Firestore
    //------------------------------------------------------

    WriteDocument(collectionid, documentid, document, true).then(() => {

      setFieldStatus('success');
      setFieldError('');

      // Reset the field status after a 5 second delay
      return setInterval(() => {

        setFieldStatus('onload');

      }, 5000);

    }).catch((error) => {

      console.log('error', error);
      setFieldStatus('error');
      setFieldError('Failed to save, refresh and try again.');

    });

  }

  //------------------------------------------------------
  //  useEffects
  //------------------------------------------------------

  // Handles field updates from document listeners
  useEffect(() => {

    if (defaultValue === undefined) return;

    setFieldValue(defaultValue);

  }, [defaultValue]);

  //------------------------------------------------------
  //  Text Field Input
  //------------------------------------------------------

  if (type === 'text') {

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? 
          (

            <input
              style={styleInput}
              className='Input-Field-Text'
              type='text'
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={(e) => handleBlur(e.target.value)}
              disabled={disabled}
              placeholder={placeholder}
            ></input>

          ) 
          :

          // pending
          fieldStatus === 'pending' ? 
          (

            <div className='flex flex-row gap-2'>
              <input
                style={styleInput}
                className='Input-Field-Text'
                type='text'
                value={fieldValue}
                disabled
              ></input>
              <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>
            </div>

          ) 
          :

          // success
          fieldStatus === 'success' ? 
          (

            <div className='flex flex-row gap-2'>
              <input
                style={styleInput}
                className='Input-Field-Text'
                type='text'
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}
                onBlur={(e) => handleBlur(e.target.value)}
                disabled={disabled}
              ></input>
              <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>
            </div>

          ) 
          :

          // Catch All --> error
          (

            <div className='flex flex-col gap-0'>
              <div className='flex flex-row gap-2'>
                <input
                  style={styleInput}
                  className='Input-Field-Text-Error'
                  type='text'
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={(e) => handleBlur(e.target.value)}
                ></input>
                <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>
              </div>
              <label className='text-[#DE0000] font-semibold'>{fieldError}</label>
            </div>

          )

        }

      </div>
    )

  }

  //------------------------------------------------------
  //   Multi Select
  //------------------------------------------------------

  else if (type === 'multiselect') {

    if (options === undefined || options.length === 0) return 'You are missing the "options" prop'

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? (

            <div className='flex flex-col gap-0'>
              <p hidden={disabled} className='text-sm text-slate-600 mb-2 p-0'>To select more than one, press Ctrl and select your options.</p>

              <select
                style={styleInput}
                className='Input-Field-MultiSelect'
                onChange={(e) => setFieldValue(Array.from(e.target.selectedOptions, (option) => option.value))}
                onBlur={() => handleBlur(fieldValue)}
                value={fieldValue}
                multiple
                disabled={disabled}
              >
                <option hidden value=''>-</option>
                {
                  options.map((choice, index) => (
                    <option key={index} value={choice}>{choice}</option>
                  ))
                }
              </select>
            </div>

          ) :

            // pending
            fieldStatus === 'pending' ? (

              <div className='flex flex-col gap-0'>
                <p hidden={disabled} className='text-sm text-slate-600 mb-2 p-0'>To select more than one, press Ctrl and select your options.</p>

                <div className='flex flex-row gap-2'>
                  <select
                    style={styleInput}
                    className='Input-Field-MultiSelect'
                    multiple
                    value={fieldValue}
                    disable
                  >
                    <option hidden value=''>-</option>
                    {
                      options.map((choice, index) => (
                        <option key={index} value={choice}>{choice}</option>
                      ))
                    }

                  </select>
                  <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>
                </div>
              </div>

            ) :

              // success
              fieldStatus === 'success' ? (

                <div className='flex flex-col gap-0'>
                  <p hidden={disabled} className='text-sm text-slate-600 mb-2 p-0'>To select more than one, press Ctrl and select your options.</p>

                  <div className='flex flex-row gap-2'>
                    <select
                      style={styleInput}
                      className='Input-Field-MultiSelect'
                      onChange={(e) => setFieldValue(Array.from(e.target.selectedOptions, (option) => option.value))}
                      onBlur={() => handleBlur(fieldValue)}
                      value={fieldValue}
                      multiple
                      disabled={disabled}
                    >
                      <option hidden value=''>-</option>
                      {
                        options.map((choice, index) => (
                          <option key={index} value={choice}>{choice}</option>
                        ))
                      }

                    </select>
                    <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>
                  </div>
                </div>

              ) :

                // Catch All --> error
                (

                  <div className='flex flex-col gap-0'>
                    <p hidden={disabled} className='text-sm text-slate-600 mb-2 p-0'>To select more than one, press Ctrl and select your options.</p>

                    <div className='flex flex-row gap-2'>
                      <select
                        style={styleInput}
                        className='Input-Field-MultiSelect-Error'
                        onChange={(e) => setFieldValue(Array.from(e.target.selectedOptions, (option) => option.value))}
                        onBlur={() => handleBlur(fieldValue)}
                        value={fieldValue}
                        multiple
                      >
                        <option hidden value=''>-</option>
                        {
                          options.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                          ))
                        }
                      </select>
                      <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>
                    </div>

                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>
                  </div>

                )
        }

      </div>
    )

  }

  //------------------------------------------------------
  //   Choice (Single)
  //------------------------------------------------------

  else if (type === 'choice') {

    if (options === undefined || options.length === 0) return 'You are missing the "options" prop'

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? (

            <div className='flex flex-col gap-0'>
              <select
                style={styleInput}
                className='Input-Field-Select'
                onChange={(e) => setFieldValue(e.target.value)}
                onBlur={() => handleBlur(fieldValue)}
                value={fieldValue}
                disabled={disabled}
              >
                <option hidden value=''>-</option>
                {
                  options.map((choice, index) => (
                    <option key={index} value={choice}>{choice}</option>
                  ))
                }

              </select>
            </div>

          ) :

            // pending
            fieldStatus === 'pending' ? (

              <div className='flex flex-row gap-2'>

                <select
                  style={styleInput}
                  className='Input-Field-Select'
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={() => handleBlur(fieldValue)}
                  value={fieldValue}
                  disabled
                >
                  <option hidden value=''>-</option>
                  {
                    options.map((choice, index) => (
                      <option key={index} value={choice}>{choice}</option>
                    ))
                  }

                </select>

                <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>
              </div>

            ) :

              // success
              fieldStatus === 'success' ? (

                <div className='flex flex-row gap-2'>

                  <select
                    style={styleInput}
                    className='Input-Field-Select'
                    onChange={(e) => setFieldValue(e.target.value)}
                    onBlur={() => handleBlur(fieldValue)}
                    value={fieldValue}
                    disabled={disabled}
                  >
                    <option hidden value=''>-</option>
                    {
                      options.map((choice, index) => (
                        <option key={index} value={choice}>{choice}</option>
                      ))
                    }

                  </select>

                  <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>

                </div>

              ) :

                // Catch All --> error
                (

                  <div className='flex flex-col gap-0'>

                    <div className='flex flex-row gap-2'>

                      <select
                        style={styleInput}
                        className='Input-Field-Select-Error'
                        onChange={(e) => setFieldValue(e.target.value)}
                        onBlur={() => handleBlur(fieldValue)}
                        value={fieldValue}
                      >
                        <option hidden value=''>-</option>
                        {
                          options.map((choice, index) => (
                            <option key={index} value={choice}>{choice}</option>
                          ))
                        }

                      </select>

                      <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>

                    </div>

                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>

                  </div>

                )

        }

      </div>
    )

  }

  //------------------------------------------------------
  //   Multiline (Text)
  //------------------------------------------------------

  else if (type === 'multiline') {

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? (

            <div className='flex flex-col gap-0'>
              <textarea
                style={styleInput}
                className='Input-Field-TextArea'
                value={fieldValue}
                onChange={(e) => setFieldValue(e.target.value)}
                onBlur={() => handleBlur(fieldValue)}
                disabled={disabled}
                placeholder={placeholder}
              ></textarea>
            </div>

          ) :

            // pending
            fieldStatus === 'pending' ? (

              <div className='flex flex-row gap-2'>

                <textarea
                  style={styleInput}
                  className='Input-Field-TextArea'
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={() => handleBlur(fieldValue)}
                  disabled={true}
                ></textarea>

                <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>
              </div>

            ) :

              // success
              fieldStatus === 'success' ? (

                <div className='flex flex-row gap-2'>

                  <textarea
                    style={styleInput}
                    className='Input-Field-TextArea'
                    value={fieldValue}
                    onChange={(e) => setFieldValue(e.target.value)}
                    onBlur={() => handleBlur(fieldValue)}
                    disabled={disabled}
                  ></textarea>

                  <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>

                </div>

              ) :

                // Catch All --> error
                (

                  <div className='flex flex-col gap-0'>

                    <div className='flex flex-row gap-2'>

                      <textarea
                        style={styleInput}
                        className='Input-Field-TextArea-Error'
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                        onBlur={() => handleBlur(fieldValue)}
                        disabled={disabled}
                      ></textarea>

                      <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>

                    </div>

                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>

                  </div>

                )

        }

      </div>
    )

  }

  //------------------------------------------------------
  //   Date String
  //------------------------------------------------------

  else if (type === 'date-string') {

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? (

            <input
              style={styleInput}
              className='Input-Field-Text'
              type='date'
              placeholder='Enter date'
              value={fieldValue}
              onChange={(e) => setFieldValue(e.target.value)}
              onBlur={(e) => handleBlur(e.target.value)}
              disabled={disabled}
            ></input>

          ) :

            // pending
            fieldStatus === 'pending' ? (

              <div className='flex flex-row gap-2'>
                <input
                  style={styleInput}
                  className='Input-Field-Text'
                  type='date'
                  placeholder='Enter date'
                  value={fieldValue}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={(e) => handleBlur(e.target.value)}
                  disabled={true}
                ></input>
                <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>
              </div>

            ) :

              // success
              fieldStatus === 'success' ? (

                <div className='flex flex-row gap-2'>
                  <input
                    style={styleInput}
                    className='Input-Field-Text'
                    type='date'
                    placeholder='Enter date'
                    value={fieldValue}
                    onChange={(e) => setFieldValue(e.target.value)}
                    onBlur={(e) => handleBlur(e.target.value)}
                    disabled={disabled}
                  ></input>
                  <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>
                </div>

              ) :

                // Catch All --> error
                (

                  <div className='flex flex-col gap-0'>
                    <div className='flex flex-row gap-2'>
                      <input
                        style={styleInput}
                        className='Input-Field-Text-Error'
                        type='date'
                        placeholder='Enter date'
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value)}
                        disabled={disabled}
                      ></input>
                      <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>
                    </div>
                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>
                  </div>

                )

        }

      </div>
    )

  }

  //------------------------------------------------------
  //   Timestamp (Read Only)
  //------------------------------------------------------

  else if (type === 'timestamp') {

    return (
      <div className='FormComponent-Row'>

        {label}

        <input
          style={styleInput}
          className='Input-Field-Text'
          type='text'
          value={ConvertDate(fieldValue)}
          disabled={true}
        ></input>

      </div>
    )

  }

  //------------------------------------------------------
  //   Number
  //------------------------------------------------------

  else if (type === 'number') {

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? (

            <input
              style={styleInput}
              className='Input-Field-Text'
              type='text'
              value={fieldValue}
              onChange={(e) => setFieldValue(
                /^[0-9,]{0,30}$/g.test(e.target.value) ? e.target.value : fieldValue
              )}
              onBlur={(e) => handleBlur(e.target.value)}
              disabled={disabled}
              placeholder={placeholder}
            ></input>

          ) :

            // pending
            fieldStatus === 'pending' ? (

              <div className='flex flex-row gap-2'>
                <input
                  style={styleInput}
                  className='Input-Field-Text'
                  type='text'
                  value={fieldValue}
                  disabled
                  placeholder={placeholder}
                ></input>
                <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>
              </div>

            ) :

              // success
              fieldStatus === 'success' ? (

                <div className='flex flex-row gap-2'>
                  <input
                    style={styleInput}
                    className='Input-Field-Text'
                    type='text'
                    value={fieldValue}
                    onChange={(e) => setFieldValue(
                      /^[0-9,]{0,30}$/g.test(e.target.value) ? e.target.value : fieldValue
                    )}
                    onBlur={(e) => handleBlur(e.target.value)}
                    disabled={disabled}
                    placeholder={placeholder}
                  ></input>
                  <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>
                </div>

              ) :

                // Catch All --> error
                (

                  <div className='flex flex-col gap-0'>
                    <div className='flex flex-row gap-2'>
                      <input
                        style={styleInput}
                        className='Input-Field-Text-Error'
                        type='text'
                        value={fieldValue}
                        onChange={(e) => setFieldValue(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value)}
                        placeholder={placeholder}
                      ></input>
                      <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>
                    </div>
                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>
                  </div>

                )

        }

      </div>
    )

  }

  //------------------------------------------------------
  //   Radio Buttons
  //------------------------------------------------------

  else if (type === 'radio') {

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ? (

            <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
              <label className='Radio-Buttons-Container'>
                Yes
                <input
                  type='radio'
                  value='yes'
                  checked={fieldValue === 'yes'}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={(e) => handleBlur(e.target.value)}
                  disabled={disabled}
                ></input>
                <span className='Radio-Checkmark'></span>
              </label>

              <label className='Radio-Buttons-Container'>
                No
                <input
                  type='radio'
                  value='no'
                  checked={fieldValue === 'no'}
                  onChange={(e) => setFieldValue(e.target.value)}
                  onBlur={(e) => handleBlur(e.target.value)}
                  disabled={disabled}
                ></input>
                <span className='Radio-Checkmark'></span>
              </label>
            </div>

          )
            :

            // pending
            fieldStatus === 'pending' ? (

              <div className='flex flex-row gap-2'>

                <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                  <label className='Radio-Buttons-Container'>
                    Yes
                    <input
                      type='radio'
                      value='yes'
                      checked={fieldValue === 'yes'}
                      onChange={(e) => setFieldValue(e.target.value)}
                      onBlur={(e) => handleBlur(e.target.value)}
                      disabled={disabled}
                    ></input>
                    <span className='Radio-Checkmark'></span>
                  </label>

                  <label className='Radio-Buttons-Container'>
                    No
                    <input
                      type='radio'
                      value='no'
                      checked={fieldValue === 'no'}
                      onChange={(e) => setFieldValue(e.target.value)}
                      onBlur={(e) => handleBlur(e.target.value)}
                      disabled={disabled}
                    ></input>
                    <span className='Radio-Checkmark'></span>
                  </label>
                </div>

                <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>

              </div>

            ) :

              // success
              fieldStatus === 'success' ? (

                <div className='flex flex-row gap-2'>

                  <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                    <label className='Radio-Buttons-Container'>
                      Yes
                      <input
                        type='radio'
                        value='yes'
                        checked={fieldValue === 'yes'}
                        onChange={(e) => setFieldValue(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value)}
                        disabled={disabled}
                      ></input>
                      <span className='Radio-Checkmark'></span>
                    </label>

                    <label className='Radio-Buttons-Container'>
                      No
                      <input
                        type='radio'
                        value='no'
                        checked={fieldValue === 'no'}
                        onChange={(e) => setFieldValue(e.target.value)}
                        onBlur={(e) => handleBlur(e.target.value)}
                        disabled={disabled}
                      ></input>
                      <span className='Radio-Checkmark'></span>
                    </label>
                  </div>

                  <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>

                </div>

              ) :

                // Catch All --> error
                (

                  <div className='flex flex-col gap-0'>

                    <div className='flex flex-row gap-2 px-[5px] py-[0px]'>
                      <label className='Radio-Buttons-Container'>
                        Yes
                        <input
                          type='radio'
                          value='yes'
                          checked={fieldValue === 'yes'}
                          onChange={(e) => setFieldValue(e.target.value)}
                          onBlur={(e) => handleBlur(e.target.value)}
                          disabled={disabled}
                        ></input>
                        <span className={fieldError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                      </label>

                      <label className='Radio-Buttons-Container'>
                        No
                        <input
                          type='radio'
                          value='no'
                          checked={fieldValue === 'no'}
                          onChange={(e) => setFieldValue(e.target.value)}
                          onBlur={(e) => handleBlur(e.target.value)}
                          disabled={disabled}
                        ></input>
                        <span className={fieldError ? 'Radio-Checkmark-Error' : 'Radio-Checkmark'}></span>
                      </label>
                    </div>

                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>

                  </div>

                )

        }

      </div>
    )

  }

  //------------------------------------------------------
  //   User Search
  //------------------------------------------------------

  else if (type === 'user-search') {

    return (
      <div className='FormComponent-Row'>

        {label}

        {
          // onload
          fieldStatus === 'onload' ?
            (
              <UserSearchLiveFields
                defaultValue={defaultValue}
                errors={fieldStatus === 'error' ? true : false}
                handleBlur={handleBlur}
                disabled={disabled}
              ></UserSearchLiveFields>
            )
            :

            // pending
            fieldStatus === 'pending' ?
              (
                <div className='flex flex-row gap-2'>

                  <UserSearchLiveFields
                    defaultValue={defaultValue}
                    errors={fieldStatus === 'error' ? true : false}
                    handleBlur={handleBlur}
                    disabled={true}
                  ></UserSearchLiveFields>

                  <img className='w-[30px] h-[30px] self-center' src={pendingIcon} alt='pending-icon'></img>

                </div>
              )
              :

              // success
              fieldStatus === 'success' ?
                (
                  <div className='flex flex-row gap-2'>

                    <UserSearchLiveFields
                      defaultValue={defaultValue}
                      errors={fieldStatus === 'error' ? true : false}
                      handleBlur={handleBlur}
                      disabled={disabled}
                    ></UserSearchLiveFields>

                    <img className='LiveField-Success' src={successIcon} alt='success-icon'></img>

                  </div>
                )
                :

                // Catch All --> error
                (
                  <div className='flex flex-col gap-0'>

                    <div className='flex flex-row gap-2'>

                      <UserSearchLiveFields
                        defaultValue={defaultValue}
                        errors={fieldStatus === 'error' ? true : false}
                        handleBlur={handleBlur}
                        error={true}
                        disabled={disabled}
                      ></UserSearchLiveFields>

                      <img className='w-[30px]' src={errorIcon} alt='error-icon'></img>

                    </div>

                    <label className='text-[#DE0000] font-semibold'>{fieldError}</label>

                  </div>
                )

        }

      </div>
    )

  }


  //------------------------------------------------------
  //   Lookup
  //------------------------------------------------------

  else if (type === 'lookup') {
    return (
      <div className='FormComponent-Row'>

        {label}

        <LookUpLiveFields
          collectionid={dataFieldCollectionid}
          defaultValue={defaultValue}
          placeholder={placeholder}
          errors={fieldStatus === 'error' ? true : false}
          disabled={disabled}
          handleBlur={handleBlur}
          fieldStatus={fieldStatus}
          fieldError={fieldError}
          dataFieldPrimary={dataFieldPrimary}
          dataFieldSecondary={dataFieldSecondary}
          dataFieldTertiary={dataFieldTertiary}
          createNewHTML={createNewHTML}
        ></LookUpLiveFields>

      </div>
    )

  }

}
